<template>
  <v-container fluid class="px-0 py-0">
    <v-row class="pl-6 pr-6">
      <v-col
        v-for="week in creneaux"
        :key="week.week"
        cols="6"
        class="pl-0 pr-0"
      >
        <v-row class="">
          <v-col class="black_line ml-4"></v-col>
          <v-col style="display: contents"
            ><div class="pl-2 pr-2">semaine {{ week.week }}</div></v-col
          >
          <v-col class="black_line mr-4"> </v-col>
        </v-row>
        <v-row no-gutters class="">
          <v-col v-for="(day, i) in week.dates" :key="i" class="ml-1 mr-1">
            <v-row no-gutters :class="[ (day.creneau[0] != undefined ? day.creneau[0].compteur >= 0 : ''|| day.creneau[1] != undefined ? day.creneau[1].compteur >= 0 : ''
               && $moment(day.creneau[0] != undefined ? day.creneau[0].date : '').isAfter($moment().add(-1, 'days'))) ||  $moment(day.creneau[1] != undefined ? day.creneau[1].date : '').isAfter($moment().add(-1, 'days'))  ? '' : 'flou', 'size']">
              <v-col
                cols="12"
                class="date_calendar d-flex flex-column align-center"
                style="box-shadow: 0px 0px 100px grey"
              >
                <p class="mb-0 mt-4" style="font-weight: bold">
                  {{ $moment(day.date).format("ddd") }}
                </p>
                <p>{{ $moment(day.date).format("DD/MM") }}</p>
              </v-col>
              <v-col cols="12" class="">
                <v-row no-gutters>
                  <!-- matin -->
                  <div
                    class="w100 matin"
                    v-for="creneau in day.creneau"
                    :key="creneau.id_creneau"
                  >
                    <v-col
                      v-if="
                        day.creneau != 'AM' &&
                          $moment(creneau.heure_debut, 'hh:mm').isBefore(
                            $moment('12:00:00', 'hh:mm')
                          ) &&
                          $moment(creneau.date).isAfter(
                            $moment().add(-1, 'days')
                          )
                      "
                      @click="
                        currentCreneau.Creneau = creneau != 'AM' ? creneau : {};
                        toShowPopup(creneau);
                      "
                      cols="12"
                      :class="[
                        creneau != 'AM' &&
                        $moment(creneau.heure_debut, 'hh:mm').isBefore(
                          $moment('12:00:00', 'hh:mm')
                        ) &&
                        $moment(creneau.date).isAfter($moment().add(-1, 'days'))
                          ? 'creneau'
                          : 'noCreneau',
                        creneau == 'AM' || creneau.compteur == 0
                          ? 'noplace'
                          : 'place',
                      ]"
                    >
                      <span
                        v-if="
                          $moment(creneau.date).isAfter(
                            $moment().add(-1, 'days')
                          ) &&
                            creneau != 'AM' &&
                            creneau.compteur > 0 &&
                            creneau.heure_debut < '12:00:00'
                        "
                        >{{ creneau.compteur }}/{{ creneau.max_collecte }}
                      </span>
                    </v-col>
                    <v-col cols="12" class="noCreneau" v-if="creneau == 'AM' || $moment(creneau.date).isBefore(
                            $moment().add(-1, 'days')) ">
                    </v-col>
                  </div>
                  <!-- apres midi -->
                  <div
                    class="w100"
                    v-for="creneau in day.creneau"
                    :key="creneau.id_creneau"
                  >
                    <v-col
                      v-if="
                        creneau != 'PM' &&
                          $moment('12:00:00', 'hh:mm').isBefore(
                            $moment(creneau.heure_debut, 'hh:mm')
                          ) &&
                          $moment(creneau.date).isAfter(
                            $moment().add(-1, 'days')
                          )
                      "
                      @click="
                        currentCreneau.Creneau = creneau != 'PM' ? creneau : {};
                        toShowPopup(creneau);
                      "
                      cols="12"
                      :class="[
                        creneau != 'PM' &&
                        $moment('12:00:00', 'hh:mm').isBefore(
                          $moment(creneau.heure_debut, 'hh:mm')
                        ) &&
                        $moment(creneau.date).isAfter($moment().add(-1, 'days'))
                          ? 'creneau'
                          : 'noCreneau',
                        creneau == 'PM' || creneau.compteur == 0
                          ? 'noplace'
                          : 'place',
                      ]"
                    >
                      <span
                        v-if="
                          $moment(creneau.date).isAfter(
                            $moment().add(-1, 'days')
                          ) &&
                            creneau != 'PM' &&
                            creneau.compteur > 0 &&
                            creneau.heure_debut > '12:00:00'
                        "
                        >{{ creneau.compteur }}/{{ creneau.max_collecte }}
                      </span>
                    </v-col>
                    <v-col cols="12" class="noCreneau" v-if="creneau == 'PM'">
                    </v-col>
                  </div>
                </v-row>

                              <!-- double -->
              <v-row v-if="day.creneau[0] == undefined" no-gutters>

                  <!-- matin -->
                  <div
                    class="w100 matin"

                  >


                    <v-col


                      cols="12"
                      class="noCreneau"
                    >
                      <span
                         
                        >
                      </span>
                    </v-col>

                  </div>
                  <!-- apres midi -->
                  <div
                    class="w100"
                  >
                    <v-col

                      cols="12"
                      class="noCreneau"
                    >
                      <span

                        >
                      </span>
                    </v-col>

                  </div>
                  <!-- <span>{{day.creneau}}</span> -->
                </v-row>

              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pl-1 pr-1 mb-4">
      <v-col cols="3">
        <v-btn
          outlined
          color="#af2c70"
          @click="$emit('moreDate', -1)"
          class="btn_voir_plus"
          :disabled="offset <= 0"
          >VOIR P&Eacute;RIODE PR&Eacute;C&Eacute;DENTE</v-btn
        >
      </v-col>
      <v-col cols="3" offset="6">
        <v-btn
          outlined
          color="#af2c70"
          @click="$emit('moreDate', 1)"
          class="btn_voir_plus"
          >VOIR P&Eacute;RIODE SUIVANTE</v-btn
        >
      </v-col>
    </v-row>
    <Popup
      :show="showPopup"
      :client="$store.state.client"
      :creneau="currentCreneau"
      @close="showPopup = false"
      :title="title"
      :info="info"
      :btn="btn"
      @confirmRdv="$emit('confirmRdv', currentCreneau);openEndDialog()"
      :panier="panier"
      :mode="'modify'"
    ></Popup>
    <div id="snack">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="red"
        elevation="24"
      >
        {{ message }}
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import Popup from "../popups/modifyCollectePop.vue";

export default {
  data() {
    return {
      showPopup: false,
      currentCreneau: { Creneau: {} },
      title: "CONFIRMATION DU RENDEZ-VOUS",
      info: "Vous recevrez un rappel par email/sms la veille du rendez-vous",
      btn: { title: "VALIDER LE RENDEZ-VOUS", action: "confirmRdv" },
      alert: false,
      panier: [],
      url: process.env.VUE_APP_BASE_URL,
      snackbar: false,
      timeout: 3000,
      message:
        "Merci d'ajouter au moins un produit au panier avant de sélectionner une date",
    };
  },
  components: { Popup },
  props: {
    creneaux: {
      type: Array,
      default: () => [],
    },
    offset: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    toShowPopup(creneau) {
      if (creneau.compteur == 0) {
        this.message =
          "Plus de créneau disponible, merci d'en sélectionner un nouveau";
        this.snackbar = true;
      } else {
        console.log("c'est ici", creneau);
        if (
          creneau != false &&
          this.$moment(creneau.date).isAfter(this.$moment().add(-1, "days"))
        ) {
          this.panier = [];
          let collect = this.$store.getters.getCollecteCollectibles;
          console.log(collect, "collecte");
          console.log(this.$store.getters.getCollecteCollectibles, "store");
          if (collect.length > 0) {
            this.showPopup = true;
            this.alert = false;
            console.log("test avant boucle");
            collect.forEach((coll) => {
              console.log("test");
              coll["url_img"] = this.url + coll.Categorie.url_logo;
              this.panier.push(coll);
              console.log("oui la 3 ", this.panier);
            });
            if (this.$route.name == "update_planning") {
              this.btn = {
                title: "VALIDER LE NOUVEAU RENDEZ-VOUS",
                action: "confirmRdv",
              };
              this.title = "CONFIRMATION D'UN NOUVEAU RENDEZ-VOUS";
            }
            console.log(this.$route.name, "route", this.btn);
          }
          if (this.$store.state.collecteCollectibles.length == 0) {
            this.snackbar = true;
          }
        }
      }
    },
    openEndDialog(){
      console.log('end of diaglog')
    }
  },

  created() {
    console.log("creneaux ", this.creneaux);
  },
};
</script>

<style scoped>
.noCreneau {
  background-color: lightgray;
}

.creneau.place {
  background-color: #93b121;
  cursor: pointer;
}
.creneau.noplace {
  background-color: #d61111;
}
.creneau,
.noCreneau {
  height: 150px;
}
.v-btn {
  min-width: 0 !important ;
}
.creneau.place > .v-btn {
  background-color: #93b121;
}
button.btn_voir_plus.v-btn.v-btn--outlined.theme--light.v-size--default {
  border-radius: 10px;
}
.flou {
  opacity: 0.5;
}

.pr-0.col.col-6 {
  height: 45%;
}
.col.col-12.creneau.place,
.col.col-12.creneau.noplace,
.col.col-12.noCreneau {
  /* max-height: 5.5rem; */
  max-height: 7.5rem;
}
.w100 {
  width: 100%;
}
.w100.matin {
  margin-bottom: 1px;
}
@media screen and (max-width: 1440px) {
  .col.col-12.noCreneau,
  .col.col-12.creneau {
    height: 4.8rem;
  }
}
</style>