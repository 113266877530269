import axios from 'axios';

const RESSOURCE_NAME = 'collectibles';


export default {
    getAllCollectibles(search) {
        return axios.post(`${RESSOURCE_NAME}/`, { search: search });
    },

    getAllCollectiblesFromCategorie(id_categorie, search) {
        return axios.post(`${RESSOURCE_NAME}/categorie/${id_categorie}`, { search: search });
    },

    createCollectible(collectible) {
        return axios.post(`${RESSOURCE_NAME}/create`, { product: collectible })
    },

    modifyCollectible(collectible, id_collectible) {
        return axios.put(`${RESSOURCE_NAME}/${id_collectible}`, { product: collectible })
    },

    deleteCollectible(id_collectible) {
        return axios.delete(`${RESSOURCE_NAME}/${id_collectible}`)
    },

    getCollectible(id_collectible) {
        return axios.get(`${RESSOURCE_NAME}/${id_collectible}`)
    }
};