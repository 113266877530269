// import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdi',
        // checked: 'fa fa-check-square',
        // checkboxOn: 'fa fa-square'
    },
}

export default new Vuetify(opts)

