<template>
  <v-row no-gutters>
    <v-col cols="12" lg="12" md="12" sd="12" class="text-left">
      <v-card class="card" elevation="0" py-0>
        <h2 class="purple_subtitle ml-3 pt-4">Saisie libre</h2>
        <div class="line ml-3"></div>

        <validation-observer ref="form">
          <form @submit.prevent="validate('submit', newCollectible)">
            <v-row class="d-flex flex-row" style="width: 100%">
              <v-col cols="5" lg="5" md="5" sm="6">
                <ValidationProvider name="libelle" rules="required">
                  <v-text-field
                    solo
                    dense
                    flat
                    placeholder="Libellé *"
                    class="text-field ml-3 "
                    v-model="newCollectible.libelle"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="2" lg="2" md="2" sm="3">
                <ValidationProvider
                  name="cubage"
                  :rules="{
                    required: true,
                    min_value: 0.01,
                    regex: /^\d{1,10}([.,]\d{1,4})?$/,
                  }"
                >
                  <v-text-field
                    solo
                    dense
                    flat
                    placeholder="Cubage *"
                    class="volume ml-0 "
                    v-model="newCollectible.volume"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="2" lg="2" md="2" sm="3">
                <ValidationProvider
                  name="poids"
                  :rules="{
                    required: true,
                    min_value: 0.001,
                    regex: /^\d{1,10}([.,]\d{1,4})?$/,
                  }"
                >
                  <v-text-field
                    solo
                    dense
                    flat
                    placeholder="Poids *"
                    class="volume ml-0"
                    v-model="newCollectible.poids"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="3" lg="3" md="3" sm="12" class="ml-sm-3 ml-md-0 ">
                <button
                  class="addProduct"
                  @click="collectibles.push(newCollectible)"
                >
                  AJOUTER
                </button>
              </v-col>
            </v-row>
          </form>
        </validation-observer>

        <div id="snack">
          <v-snackbar
            v-for="error in errors"
            :key="error"
            v-model="snackbar"
            :timeout="timeout"
            right
            rounded="pill"
            color="red darken-2"
            elevation="24"
          >
            {{ error }}
          </v-snackbar>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "addNewProduct",
  data() {
    return {
      snackbar: true,
      timeout: 3000,
      errors: [],
      collectibles: [],
      // newCollectible: {
      //   Categorie: {
      //     id: null,
      //     libelle: "saisie libre",
      //     url_logo: process.env.VUE_APP_BASE_URL + "categories/camion.png",
      //   },
      //   id_collectible: null,
      //   libelle: "",
      //   volume: null,
      //   poids: null,

      //   prix: null,
      // },
      newCollectible: [],
    };
  },
  watch: {
    async search(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log("newValue", newValue, "oldValue", oldValue, "watch parent");
        this.search = newValue;
      }
    },
  },
  components: {},
  async created() {},
  methods: {
    validate: function(event, newCollectible) {
      console.log(event, "event");
      // this.collectibles.push(newCollectible)
      if (event == "submit") {
        this.$refs.form.validate().then((valid) => {
          if (valid) {
            let c = { ...newCollectible };
            c.volume = c.volume.replace(",", ".");
            c.poids = c.poids.replace(",", ".");
            //  console.log(c, "array");
            // newCollectible["quantity"] = 1;

            console.log(c, "newCollectible");

            // if(this.$store.state.collecteCollectibles.length > 0){
            //   let isUpdate = 0;
            //    this.$state.collecteCollectibles.forEach((collectible, index) => {
            //   console.log('boucle',collectible.id_collectible ,newCollectible.id_collectible)
            //   if (collectible.id_collectible == newCollectible.id_collectible) {
            //     // mis à jour de la quantité si produit existe dans le cart
            //     this.$store.commit("updateCollectible", {
            //       quantity: parseInt(collectible.quantity) + 1,
            //       index: index,
            //     });
            //     isUpdate = 1;
            //   }
            // });
            // if(isUpdate == 0) {
            //   // ajout nouveau produit
            //     this.$set(newCollectible, "quantity", 1);
            //     this.$store.commit("addCollectibles", newCollectible);
            //   }
            // }else{
            // ajout si pas de produit dans le cart
            this.$set(c, "quantity", 1);
            this.$store.commit("addCollectibles", c);
            // }

            /* Reinitialise les objets de la saisie libre ????? */

            this.newCollectible.libelle = "";
            this.newCollectible.volume = null;
            this.newCollectible.poids = null;
          } else {
            //display error
            console.log(valid);
            this.errors = [];
            let e = this.$refs.form.errors;
            for (const field in e) {
              e[field].forEach((msg) => {
                msg = msg.includes("invalide")
                  ? msg.split("est")[0] + "doit être un entier ou un décimal"
                  : msg;
                this.errors.push(msg);
              });

              this.snackbar = true;
            }
          }
        });
      }
    },
    newObject() {
      this.newCollectible = {
        Categorie: {
          id: null,
          libelle: "saisie libre",
          url_logo: "categories/saisieLibre.png",
        },
        id_collectible: null,
        libelle: "",
        volume: null,
        poids: null,

        prix: null,
      };
    },
  },
  mounted() {
    this.newObject();
  },
};
</script>

<style lang="css" scoped>
/* .font_1 {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 1;
  font-size: 2.85714286rem;
  margin-bottom: -10px;
} */

.card {
  background-color: white;
  border-radius: 0px 0 15px 15px;
}

.purple_subtitle {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #af2c70 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 1.14285714rem;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #af2c70;
  align-self: center;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.text-field,
.volume {
  font: italic normal 100 24px/30px Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
  margin-left: 1vw;
  width: 100%;
}

.v-input {
  border: 1px solid #e3e3e3 !important;
  border-radius: 15px !important;
  height: 65px;
  padding-left: 1vw;
}

::v-deep .text-field input::placeholder,
::v-deep .volume input::placeholder {
  font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

/* ::v-deep .text-field input::placeholder .volume input::placeholder {
font: italic normal bold 1.71rem/2.14rem  Roboto;
letter-spacing: 0px;
color: #333333 !important;
font-size: 1.4rem;
} */

::v-deep .v-input__slot {
  margin-top: 10px !important;
}

::v-deep .v-text-field__slot {
  margin-left: 0px;
}

.addProduct {
  width: 100%;
  /* max-width: 200px; */
  height: 65px;
  border-radius: 15px;
  background-color: #af2c70;
  font: normal normal bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

@media screen and (max-width: 1366px) {
  .v-input {
    padding-left: 0vw;
  }

  ::v-deep .text-field input::placeholder,
  ::v-deep .volume input::placeholder {
    font-size: 1.45rem;
  }
}
</style>
