<template>
  <v-card height="100%" color="#002b47">
    <v-row class="ma-0 pa-0">
      <v-col cols="7" class="ma-O pa-0">
        <v-card-title class="card-title pb-0"
          >À COLLECTER({{ countCollectible }})</v-card-title
        >
        <div class="line-title ml-4"></div>
      </v-col>

      <v-col cols="5" class="ma-0 pa-0 text-right">
        <img
          class="mt-5 pr-4"
          width="120"
          src="@/assets/images/collecte/camion.png"
          alt="Truck"
        />
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col> <div class="line mt-5"></div></v-col>
    </v-row>
    <v-card-text v-if="allCollectibles == 0">
      <v-col cols="12" fill-height align="center" class="ma-0 pa-0">
        <img class="mt-16" src="@/assets/images/collecte/vide.png" alt="Vide" />
        <p class="no-object">
          Vous n'avez pas d'objet <br />
          à collecter pour le moment
        </p>
      </v-col>
    </v-card-text>
    <v-card-text class="px-0" v-else>
      <v-data-iterator
        :items="allCollectibles"
        item-key="name"
        hide-default-footer
        no-data-text=""
        :disable-pagination="true"
      >
        <template v-slot:default="{ items }">
          <v-row
            v-for="(coll, i) in items"
            :key="coll.libelle"
            align="center"
            no-gutters
          >
            <v-col align-center cols="2">
              <img
                class="ml-2"
                width="50"
                :src="
                  coll && coll.Categorie ? url + coll.Categorie.url_logo : ''
                "
                alt="Collectible"
              />
            </v-col>
            <v-col xl="4" lg="3">
              <p class="libelle-panel ml-2 text-left">
                {{ coll.libelle ? coll.libelle : "" }}
              </p>
            </v-col>
            <v-col align="center" xl="3" lg="4">
              <p class="volume-panel">
                {{ coll ? coll.volume : "" }}m³ - {{ coll ? coll.poids : "" }}kg
              </p>
            </v-col>

            <v-col cols="2">
              <v-text-field
                @change="updateCart(coll.quantity, i)"
                label="0"
                solo
                v-model="coll.quantity"
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col align="center" justify="center" cols="1">
              <img
                @click="
                  showPopup = true;
                  collectibleDelete = i;
                "
                class="cross"
                src="@/assets/close.png"
                alt="Cross"
              />
            </v-col>

            <v-col
              cols="12"
              class="px-4 py-4"
              v-if="
                allCollectibles.length >= 2 && i + 1 < allCollectibles.length
              "
            >
              <v-divider class="diviser"></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
    <!-- <v-pagination
      v-model="page"
      :length="nbPageDisplay"
      class="mb-8 mt-8"
      v-if="nbPageDisplay >= 2"
    ></v-pagination> -->
    <div class="bottom px-4">
      <v-row>
        <v-col cols="12" class="px-4 py-4">
          <v-divider class="diviser final"></v-divider>
        </v-col>
      </v-row>
      <v-row  width="100%" class="mt-5  pt-0 pb-4 d-flex align-center">
        <v-col cols="6" class="text-left d-flex align-center  "><span class="total ">Total</span> <i  v-if="parseFloat(totalVol) >= 2.1 && $store.state.client.id_type_client == 1 ||parseFloat(totalVol) >= 20.1 && $store.state.client.id_type_client == 2" class="fas fa-exclamation-triangle"></i> </v-col>
        <v-col cols="6" class="text-right d-flex justify-end align-center"> <span class="volume ">{{ totalVol }}m3 - {{ totalP }}kg</span></v-col>
        
       
      </v-row>
      <v-row>
        <v-col v-if="$route.name == 'newCollecte'">
          <v-btn
            align="center"
            justify="center"
            color="#af2c70"
            large
            :disabled="isDisabled"
            class="py-8 px-10 rounded-lg collect-btn white--text mb-4"
            @click="goTo('planning')"
            >CHOISIR UNE DATE DE COLLECTE</v-btn
          >
        </v-col>
        <v-col v-if="$route.name == 'updateCollecte'">
          <v-btn
            align="center"
            justify="center"
            color="#af2c70"
            large
            :disabled="isDisabled"
            class="py-8 px-10 rounded-lg collect-btn white--text mb-4"
            @click="goTo('update_planning')"
            >CHOISIR UNE DATE DE COLLECTE</v-btn
          >
        </v-col>
                        <v-col v-if="$route.name == 'updateCollecte'">
          <v-btn
            align="center"
            justify="center"
            color="#af2c70"
            large
            :disabled="isDisabled"
            class="py-8 px-10 rounded-lg collect-btn white--text mb-4"
            @click="dialog = true"
            >ENREGISTRER LE PANIER</v-btn
          >
        </v-col>
      </v-row>
      <v-row>

      </v-row>
    </div>

    <confirmPop
      :title="title"
      :btn="btn"
      :show="showPopup"
      @close="showPopup = false"
      @delete="deleteElement"
    ></confirmPop>

    <Popup
      :show="dialog"
      :client="$store.state.client"
      :creneau="currentCreneau"
      @close="dialog = false"
      :title="titleModifyPopup"
      :info="infoModifyPopup"
      :btn="btnModifyPopup"
      @confirmRdv="$emit('confirmRdv', currentCreneau);updateCollecte()"
      :panier="updateCollecteObj.panier"
      :mode="'modify'"
    ></Popup>

    <!-- <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card style="font-weight: bold; color: #333; font-size: 2rem;">Le panier a été mis à jour</v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import confirmPop from "@/components/popups/confirmPop";
import apiCollecte from "@/api/collectes";
import Popup from "../popups/modifyCollectePop.vue";
// import { mapGetters } from "vuex";

export default {
  name: "panel",

  components: {
    confirmPop, Popup
  },

  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      showPopup: false,
      allCollectibles: [],
      title: "Etes vous sûr de vouloir supprimer ce collectible ?",
      btn: [
        { label: "Oui", type: "btn", action: "delete" },
        { label: "Non", type: "btn", action: "close" },
      ],
      collectibleDelete: 0,
      page: 1,
      itemsPerPage: parseInt(process.env.VUE_APP_NB_ITEMS_CART),
      updateCollecteObj: {
        panier: [],
        id_client: "",
        id_creneau: "",
      },
      dialog: false,
      currentCreneau: { Creneau: {} },
      titleModifyPopup: "MODIFICATION DU PANIER",
      infoModifyPopup: "Vous recevrez un rappel par email/sms la veille du rendez-vous",
      btnModifyPopup: { title: "VALIDER LE RENDEZ-VOUS", action: "confirmRdv" },
      // nbPageDisplay: 1,
    };
  },

  watch: {
    page(newValue) {
      this.page = newValue;
    },
    //     allCollectibles(){
    // console.log(
    //         "nb display",
    //         this.allCollectibles.length,
    //         this.itemsPerPage,
    //         this.allCollectibles.length / this.itemsPerPage,
    //         Math.ceil(parseInt(this.allCollectibles.length / this.itemsPerPage))
    //       );
    //     }
  },

  computed: {
    // ...mapGetters({ allCollectibles: "getCollecteCollectibles" }),
    totalVol() {
      console.log("je passe la ");

      let vol = 0;
      this.allCollectibles.forEach((collectible) => {
        vol += collectible.volume * parseInt(collectible.quantity);
        // this.totalP += collectible.poids;*collectible.quantity
      });
      this.$emit('change_vol', Math.round(vol * 100) / 100)
      return Math.round(vol * 100) / 100;
    },
    totalP() {
      let poids = 0;
      this.allCollectibles.forEach((collectible) => {
        poids += collectible.poids * parseInt(collectible.quantity);
        // this.totalP += collectible.poids;
      });
      return poids;
    },
    countCollectible() {
      let counter = 0;
      this.allCollectibles.forEach((collectible) => {
        counter += parseInt(collectible.quantity);
        // this.totalP += collectible.poids;
      });
      return counter;
    },
    isDisabled() {
      console.log(this.$store.state.collecteCollectibles.length, "is disabled");
      return this.$store.state.collecteCollectibles.length == 0 ? true : false;
    },
    nbPageDisplay() {
      return Math.ceil(this.allCollectibles.length / this.itemsPerPage);
    },
  },

  methods: {
    get_id_creneau() {
        apiCollecte
            .getCollecte(this.$route.params.id_collecte)
            .then((res) => {
              this.currentCreneau.Creneau = res.data.collecte[0].Creneau
              this.updateCollecteObj.id_creneau = res.data.collecte[0].Creneau.id_creneau;
              this.updateCollecteObj.panier = this.$store.state.collecteCollectibles;

            });
    },
    getCollectible() {
      this.allCollectibles = this.$store.state.collecteCollectibles;
    },

    updateCollecte() {
      this.updateCollecteObj.id_client = this.$store.state.client.id_client;
      this.updateCollecteObj.id_type_client = this.$store.state.client.id_type_client;
      this.updateCollecteObj.id_marche = this.$store.state.client.id_marche;
      console.log(this.updateCollecteObj, "updateCollecteObj")
      this.$store.commit('creneauSelected',this.currentCreneau.Creneau)
      apiCollecte.update(this.updateCollecteObj, this.$route.params.id_collecte)
          .then((res) => {
            if (res.data.status == "success") {
            this.$store.commit('isFromUpdatePanier',true);
            this.$store.commit('tarifCollecte',res.data.Tarif);
              setTimeout(() => {
                this.$router.push({
                  name: "client",
                  id_client: this.$route.params.id_client,
                });
                this.dialog = false;
              }, 1000);
            }
          });
    },

    addCart() {
      let collectibles = this.$store.state.collecteCollectibles;

      collectibles.forEach((collectible) => {
        this.allCollectibles.push(collectible);
        this.totalVol += collectible.volume;
        this.totalP += collectible.poids;
      });

      console.log("Ptet ", this.totalVol);
    },
    updateCart(quantity, index) {
      console.log("update", quantity, index);
      if (quantity > 0) {
        this.$store.commit("updateCollectible", {
          quantity: parseInt(quantity),
          index: index,
        });
      } else {
        this.$store.commit("deleteElementCart", index);
      }
    },
    deleteElement() {
      this.$store.commit("deleteElementCart", this.collectibleDelete);
      this.showPopup = false;
    },
    goTo(route) {
      if (this.$route.name == "newCollecte") {
        this.$router.push({
          name: route,
          params: { id_utilisateur: this.$route.params.id_utilisateur },
        });
      } else {
        this.$router.push({
          name: route,
          params: {
            id_utilisateur: this.$route.params.id_utilisateur,
            id_collecte: this.$route.params.id_collecte,
          },
        });
      }
    },
    nextPage() {
      if (this.page + 1 <= this.$store.state.collecteCollectibles.length)
        this.page += 1;
    },
  },

  created() {
    console.log("created panel");
    this.addCart();

    this.getCollectible();
    this.get_id_creneau() 
  },
};
</script>

<style scoped>
.card-title::v-deep {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
}

.line-title {
  width: 30px;
  background-color: #af2c70;
  padding: 2px 0;
}

.line {
  width: 100%;
  background-color: #fff;
  padding: 1px 0;
  opacity: 0.2;
}

.line2 {
  width: 330px;
  background-color: #fff;
  padding: 1px 0;
  opacity: 0.8;
}

.subline {
  width: 90%;
  background-color: #fff;
  opacity: 0.5;
  padding: 0.5px 0;
}
.diviser {
  background-color: #fff;
  opacity: 0.5;
}
.diviser.final {
  opacity: 1;
  border-width: 2px 0 0;
}

.no-object {
  color: #fff;
  opacity: 0.8;
  font-size: 1rem;
  font-style: italic;
}

.total {
  color: #af2c70;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}

.volume,
.poids {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

.cross {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.collect-btn::v-deep {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 25px;
  margin-left: 20px;
}

.libelle-panel {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}

.volume-panel {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 0;
}

.v-input__control {
  min-height: 30px;
}

.bottom {
  /* position: absolute;
  bottom: 15px; */
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #af2c70 !important;
  color: #fff !important;
  opacity: 0.5;
}

.v-input >>> .v-input__slot {
  padding: 0 !important;
}
.v-input >>> .v-input__slot input {
  text-align: center;
}
.v-card {
  border-radius: 15px;
}
.v-input {
  width: 60%;
  margin: auto !important;
}
.v-data-iterator {
  height: 428px;
  overflow-y: auto;
}

.fa-exclamation-triangle{
  color: orange;
  font-size: 2rem;
  margin-left: 1rem;
}

@media screen and (max-width: 1440px) {
  .v-data-iterator {
    height: 375px;
    overflow-y: auto;
  }
}
@media screen and (max-width: 1366px) {
  .v-data-iterator {
    height: 370px;
    overflow-y: auto;
  }
  .collect-btn::v-deep {
    margin-top: 30px;
  }

  .line {
    width: 310px;
    background-color: #fff;
    padding: 1px 0;
    opacity: 0.2;
  }

  .line2 {
    width: 310px;
    background-color: #fff;
    padding: 1px 0;
    opacity: 0.8;
  }
}
</style>
