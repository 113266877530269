<template>
  <v-row no-gutters>
    <v-col cols="2" md="2" lg="1">
      <sidebar />
    </v-col>

    <v-col cols="10" md="10" lg="11" xl="11" >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import sidebar from "@/components/shared/sidebar";

export default {
  components: {
    sidebar,
  },
};
</script>
<style scoped>
</style>