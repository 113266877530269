<template>
  <v-row no-gutters>
    <v-col cols="1">
      <sidebar />
    </v-col>

    <v-col cols="8" xl="8" class="mt60">
      <v-container>
        <slot />
      </v-container>
    </v-col>

    <v-col cols="3" xl="3" class="mt60">
      <v-container fluid>
        <chiffresClients />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import sidebar from "@/components/shared/sidebar";
import chiffresClients from "@/components/shared/chiffresClients";

export default {
  components: {
    sidebar,
    chiffresClients,
  },
};
</script>
<style scoped>
.mt60 {
  margin-top: 60px;
}
</style>
