<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <listClients />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import listClients from "@/components/admin/clients/listClients";

export default {
  name: "Clients",

  data() {
    return {
      title: "Planning du jour",
      date: "",
    };
  },

  components: {
    listClients,
  },

  created() {
    console.log("Role ", this.$store.state.user.Role.role);
  },
};
</script>

<style scoped>
@import "clients.css";
</style>
