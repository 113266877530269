<template>
  <v-card color="#002B47" class="nextCollect">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 >PROCHAINE COLLECTE</h2>
        <div class="line"></div>

        <div class="noCollect" v-if="collect.id_collecte == undefined">
          <h3>PAS DE COLLECTE</h3>
        </div>
        <div class="collect" v-if="collect.id_collecte != undefined">
          <h3>{{ $moment(collect.date_collecte).format("LL") }}</h3>
          <p class="horaire">
            {{
              $moment(collect.Creneau.heure_debut, "HH:mm:ss").format("HH[h]mm")
            }}
            -
            {{
              $moment(collect.Creneau.heure_fin, "HH:mm:ss").format("HH[h]mm")
            }}
          </p>
          <p class="details">
            <a
              @click="
                toShowPopup();
                mode = 'read';
              "
              >Voir le détail</a
            >
          </p>
        </div>
        <v-row id="btn" no-gutters>
          <v-col cols="12">
            <v-btn
              @click="goTo('newCollecte')"
              class="round xx-large white--text"
              x-large
              color="#AF2C70"
              block
              depressed
              :disabled="this.$route.name == 'createClient'"
            >
              CREER UNE NOUVELLE COLLECTE
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters
          ><p class="modifyCollect">
            <a
              @click="
                toShowPopup();
                mode = 'modify';
              "

            >
              modifier / annuler collecte </a
            >
          </p>
         
        </v-row>
         <v-row no-gutters
          ><p class="modifyCollect">
            <a
              v-if= "hasMultipleCollecte"
              @click="
                showAllCollectes();
              "
            >
              Voir toutes les collectes</a
            >
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <ModifyCollectPop
        :show="showPopup"
        @close="showPopup = false"
        :creneau="{ Creneau: collect.Creneau }"
        :client="client"
        :btn="btn"
        :btn2="btn2"
        :btn3="btn3"
        :title="title"
        @deleteColl="deleteColl($event)"
        @modifyCollecte="go('updateCollecte')"
        @removeCollecte="removeCollecte"
        :panier="panier"
        :isCommentaireMandatory="isCommentaireMandatory"
        :mode="mode"
        :call="collect.updatedAt"
      />
    </v-row>
  </v-card>
</template>
<script>
import ModifyCollectPop from "@/components/popups/modifyCollectePop";
import apiCollecte from "@/api/collectes";

export default {
  data() {
    return {
      vTotal: 0,
      pTotal: 0,
      showPopup: false,
      title: "Modification de rendez-vous",
      btn: { title: "Modifier cette collecte", action: "modifyCollecte" },
      btn2: "Annuler collecte",
      btn3: "Supprimer la collecte",
      panier: [],
      creneau: null,
      url: process.env.VUE_APP_BASE_URL,
      currentCollectId: 1,
      isCommentaireMandatory: false,
      mode: "read",
    };
  },
  components: { ModifyCollectPop },
  props: {
    collect: {
      type: Object,
      default: () => {},
    },
    infoCollecte: {
      type: Object,
      default: () => {},
    },
    client: {
      type: Object,
      default: () => {},
    },
    hasMultipleCollecte: {
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    goTo(route) {
      this.$store.commit("resetCollecte");
      this.$router.push({
        name: route,
        params: { id_user: this.$route.params.id_client },
      });
    },
    go(route) {
      console.log(route);
      this.$router.push({
        name: route,
        params: {
          id_client: this.$route.params.id_client,
          id_collecte: this.collect.id_collecte,
        },
      });
    },
    deleteColl(commentaire) {
      console.log(
        "on est dans next collect ici reception du commentaireDelete",
        commentaire
      );
      this.showPopup = false;
      this.$emit("deleteColl", {
        id_collecte: this.collect.id_collecte,
        commentaire: commentaire,
      });
      this.$router.go();
    },
    removeCollecte() {
      apiCollecte.removeCollecte(this.collect.id_collecte).then(res => {
        console.log('lesgooooo ', res.data)
      })
      this.showPopup = false;
      setTimeout(() => {
        this.$router.go();
      }, 3000);
      
    },
    toShowPopup() {
      console.log("toShowPopupddddd");
      this.panier = [];
      let collect = this.$store.getters.getCollecteCollectibles;
      console.log(collect,'cccccccccccccccccccccc');
      if (collect.length > 0) {
        this.showPopup = true;

        collect.forEach((coll) => {
          console.log("test");
          coll["url_img"] = this.url + coll.Categorie.url_logo;
          this.panier.push(coll);
          console.log("oui la 3 ", this.panier);
        });
      }
      // var diffHours = this.$moment(this.collect.date_collecte).diff(
      //   this.$moment(),
      //   "hours"
      // );

         var day_collecte = this.$moment(this.collect.date_collecte).set({hour:0,minute:0,second:0,millisecond:0});
        var diffDays = this.$moment(day_collecte).diff(this.$moment().set({hour:0,minute:0,second:0,millisecond:0}), 'day');
        // var diffDays = moment('2022-04-21 00:00:00').diff('2022-04-21 00:00:00', 'day');
        var today = day_collecte;
        var countDayOuvre = 0;


        if(diffDays >= 1){
          for( let i =0;i<diffDays;i++){

            today = today.subtract(1, 'days');
            let numberDay = today.isoWeekday();
            if(numberDay <=5){
              countDayOuvre ++
            }
          }
        }


        console.log(countDayOuvre,'tttttttttttttttt');
        this.isCommentaireMandatory = false;
      if (countDayOuvre <= 2) {
        this.isCommentaireMandatory = true;
      }
      console.log(this.showPopup, "showPopup");
    },
    showAllCollectes(){
      console.log('showAllCollectes')
      this.$router.push({
        name: 'collectes',
        params: {
          id_client: this.$route.params.id_client
        },
      });
    }
  },

  mounted() {
    console.log("infoCollecte - nextCollect.vue : ", this.collect);
  },
};
</script>
<style scoped>
@import "./nextCollect.css";
</style>
