import axios from 'axios';

const RESSOURCE_NAME = 'categories';


export default {
    getAllCategories() {
        return axios.get(`${RESSOURCE_NAME}`);
    },

    createCategorie(categorie) {
        return axios.post(`${RESSOURCE_NAME}/create`, categorie, {headers: { "Content-Type": "multipart/form-data" }})
    }
};