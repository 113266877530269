<template>
  <v-row no-gutters>
    <v-col cols="12" lg="12" class="text-left">
      <v-card class="card" elevation="0">
        <h1 v-if="$route.name == 'newCollecte'" class="font_1 ml-3 pt-4">
          NOUVELLE COLLECTE 
           <!-- <i  v-if="parseFloat(vol) >= 2.1 && $store.state.client.id_type_client == 1 ||parseFloat(vol) >= 20.1 && $store.state.client.id_type_client == 2" class="fa fa-exclamation-triangle"></i>  -->
        </h1>
        <h1 v-else class="font_1 ml-3 pt-4">MODIFIER COLLECTE  
          <!-- <i  v-if="parseFloat(vol) >= 2.1 && $store.state.client.id_type_client == 1 ||parseFloat(vol) >= 20.1 && $store.state.client.id_type_client == 2" class="fas fa-exclamation-triangle"></i>  -->
          </h1>
         
        <hr class="ml-3 mr-3" />
        <h2 class="purple_subtitle ml-3">Recherche par catégorie</h2> 
        <div class="line ml-3"></div>

        <v-row no-gutters class="ml-1" style="width: 95%">
          <v-col
            class="flex-center"
            v-for="categorie in categories"
            :key="categorie.id_categorie"
            cols="2"
            
          >
            <div
              @click="searchProductCategorie(categorie)"
              class="d-flex flex-column bloc_liste"
            >
              <v-img
                class="center-img"
                v-bind:src="categorie.url_logo"
                max-height="70"
                max-width="70"
                width="70"
                height="70"
              />
              <p v-html="categorie.libelle" class="libelle_categorie">
                <!-- {{categorie.libelle}} -->
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SearchProductPopup v-bind:dialog.sync="dialog" :categorie="categorie">
      </SearchProductPopup>
    </v-col>
  </v-row>
</template>

        
 

<script>
import api from "@/api/categories";
import SearchProductPopup from "@/components/popups/SearchProductPopup";

export default {
  name: "Categories",
  data() {
    return {
      categories: null,
      dialog: false,
      categorie: null,
    };
  },
   props: {
    vol: {
      type: Number,
      default: () => {},
    },
   },
  components: {
    SearchProductPopup,
  },
  async created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      api.getAllCategories().then((res) => {
        this.categories = res.data.categories.filter(function(value){ 
            var filtered = value.Collectibles.filter(function(val) {
              return val.active == 1
            })
            return filtered.length > 0;
        });

        // url dynamique
        this.categories.forEach((element) => {
          element["url_logo"] =
            process.env.VUE_APP_BASE_URL + element["url_logo"];
        });
        console.log(this.categories, "modif");
      });
    },

    searchProductCategorie(categorie) {
      this.dialog = true;
      this.categorie = categorie;
    },
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.flex-center.col.col-2 {
  margin: 4px 0px ;
}

.background_color {
  background-color: blueviolet;
}

.font_1 {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 1;
  font-size: 2.85714286rem;
  margin-bottom: -10px;
}

.card {
  background-color: white;
  /* border-radius: 0px 15px 15px 15px; */
}

hr {
  border-top: 1px dotted #000;
  color: #f1f1f1;
  margin-bottom: 20px;
  /*Rest of stuff here*/
}

.purple_subtitle {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #af2c70 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 1.14285714rem;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #af2c70;
  align-self: center;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.bloc_liste {
  width: 90%;
  height: 115px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.center-img {
  margin-left: auto;
  margin-right: auto;
}

.libelle_categorie {
  font-family: "Roboto", sans-serif;
  color: #333333 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 0.99rem !important;
  margin-top: -6px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 18px;
}

.flex-center {
  display: flex;
  justify-content: center;
  margin: 1% 0;
}
.fa-exclamation-triangle{
  color:orange
}

@media screen and (max-width: 1366px) {
  .bloc_liste {
    width: 90%;
    height: 115px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
  }
}

@media screen and (max-width: 810px) {
  .bloc_liste {
    width: 95%;
    height: 115px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
  }

  .center-img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }

  .libelle_categorie {
    font-family: "Roboto", sans-serif;
    color: #333333 !important;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
    font-size: 0.85rem !important;
    margin-top: -6px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 18px;
  }
}
</style>

