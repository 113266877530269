<template>
  <div id="allCommune">
    <v-col cols="12">
      <v-text-field
        solo
        dense
        flat
        placeholder="Rechercher une commune par nom ou code postal"
        class="autocomplete ml-6 mx-4"
        v-model="querySearch"
      >
        <template class="searchField" slot="append">
          <img class="search-logo" src="@/assets/search.png" />
        </template>
      </v-text-field>
      <v-row>
        <v-col cols="12" offset="">
          <v-data-table
            :headers="headers"
            :items="allCommunes"
            :options.sync="option"
            hide-default-footer
            no-data-text="Aucune donnée disponible"
            class="mx-4"
          >
            <!-- <template v-slot:[`item.commune`]="{ item }">
              {{ item.commune }}
            </template> -->
            <!-- <template v-slot:[`item.code_postal`]="{ item }">
              {{ item.code_postal }}
            </template> -->
            <template v-slot:[`item.enregistrer`]="{ item }">
              <div
                @click="$emit('addCommune', item)"
                class="delete"
                align="right"
              >
                Ajouter
              </div>
            </template>
          </v-data-table>

          <v-pagination
            v-model="pagination"
            :length="nbpage"
            circle
            class="my-6"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-row class="mb-5">
          <v-col cols="4" offset="2">
            <v-btn
              block
              class="round create"
              rounded
              color="#AF2C70"
              x-large
              depressed
              @click="$emit('createCommune')"
              :disabled="disableCreate"
            >
              Créer une commune
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              block
              class="round"
              rounded
              color="#AF2C70"
              x-large
              dark
              depressed
              @click="$emit('ReturnCommuneSecteur')"
            >
              Retour vers les communes du secteur
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    allCommunes: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: () => 0,
    },
    nbpage: {
      type: Number,
      default: () => 0,
    },
    query: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      headers: [
        { text: "COMMUNE", value: "commune", sortable: true },
        { text: "CODE POSTAL", value: "code_postal", sortable: true },
        // { text: "", align: "", value: "", sortable: false },
        // { text: "", align: "", value: "", sortable: false },
        // { text: "", align: "", value: "", sortable: false },
        { text: "", value: "enregistrer", sortable: false },
      ],
      option: {},
      disableCreate: true,
    };
  },
  methods: {
    test() {
      console.log("hello");
    },
  },

  computed: {
    pagination: {
      get() {
        return this.page;
      },
      set(newvalue) {
        return this.$emit("changePage", {
          query: this.querySearch,
          page: newvalue - 1,
        });
      },
    },
    querySearch: {
      get() {
        return this.query;
      },
      set(newvalue) {
        if (newvalue.length > 0) {
          this.disableCreate = false;
          return this.$emit("querySearch", {
            query: newvalue,
            page: 0,
          });
        }
      },
    },
  },
};
</script>

<style lang="css" scoped>
.delete {
  color: #af2c70;
  text-decoration: underline;
  cursor: pointer;
}
.theme--light.v-btn.v-btn--disabled.create {
  color: #fff !important;
  opacity: 0.5 !important;
  background-color: #af2c70 !important;
}
.theme--light.v-btn.create {
  color: #fff !important;
  background-color: #af2c70 !important;
  cursor: pointer;
}
.round {
  display: inline-block;
  white-space: normal;
}
.v-data-table >>> th:nth-child(1) {
  width: 40%;
}
.v-data-table >>> th:nth-child(2) {
  width: 40%;
}
.fa-plus {
  color: #93b121;
}
#allCommune {
  width: 100%;
}
</style>
