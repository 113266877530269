<template>
  <v-row no-gutters>
    <v-col cols="12" lg="12" md="12" sd="12" class="text-left">
      <v-card class="card py-0" elevation="0">
        <h2 class="purple_subtitle ml-3 pt-12">Rechercher dans l'abaque</h2>
        <div class="line ml-3"></div>

        <v-row style="width: 100%">
          <v-col cols="12" lg="12" md="12" sd="12">
            <v-text-field
              v-if="dialog == false"
              solo
              dense
              flat
              placeholder="Réfrigérateur, chaise, placard..."
              class="autocomplete ml-3 mt-0"
              v-model="search"
              @keydown.enter="dialog = true"
            >
              <template class="searchField" slot="append">
                <img
                  class="search-logo"
                  @click="dialog = true"
                  src="@/assets/search.png"
                />
              </template>
            </v-text-field>
          </v-col>
          <!-- <p>{{search}}</p> -->
        </v-row>
      </v-card>

      <SearchProductPopup
        v-bind:dialog.sync="dialog"
        :mode="mode"
        :searchFromParent="search"
      >
      </SearchProductPopup>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/api/collectibles";
import SearchProductPopup from "@/components/popups/SearchProductPopup";

export default {
  name: "searchProduct",
  data() {
    return {
      search: "",
      collectibles: [],
      dialog: false,
      mode: "allCollectibles",
    };
  },
  watch: {
    async search(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log("newValue", newValue, "oldValue", oldValue, "watch parent");
        this.search = newValue;
      }
    },
  },
  components: {
    SearchProductPopup,
  },
  async created() {
    await this.getAllCollectibles();
  },
  methods: {
    async getAllCollectibles(search) {
      api.getAllCollectibles(search).then((res) => {
        this.collectibles = res.data.collectibles;
       
      });
    },
  
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
/* .v-input {
    height: 50px !important ;
}
 */

.card {
  background-color: white;
  /* border-radius: 0px 15px 15px 15px; */
}

.purple_subtitle {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: #af2c70 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 1.14285714rem;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #af2c70;
  align-self: center;
  margin-top: 1vh;
  margin-bottom: 3vh;
}

.autocomplete {
  font: italic normal 100 24px/30px Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
  margin-top: 25px;
  margin-left: 1vw;
}

.v-input {
  border: 1px solid #e3e3e3 !important;
  border-radius: 15px !important;
  height: 65px;
}

::v-deep .autocomplete input::placeholder {
  font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

::v-deep .v-input__slot {
  margin-top: 10px !important;
}

::v-deep .v-text-field__slot {
  margin-left: 20px;
}

.search-logo {
  /* width: auto;
    max-width: 36px;
  height: auto; */
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .v-input {
    padding-left: 0vw;
  }

  ::v-deep .autocomplete input::placeholder {
    font-size: 1.5rem;
  }

  ::v-deep .v-text-field__slot {
    margin-left: 0px;
  }
}
</style>
