<template>
  <v-overlay :value="show">
    <v-card v-if="show2 == false" class="modif" light>
      <img id="close" src="@/assets/close.png" @click="$emit('close')" />
      <div class="scroll">
        <v-row no-gutters>
          <v-col cols="8" xl="6" offset="2" xl-offset="4">
            <h3 class="mt-5 uppercasePopup title">
              {{ title }}
            </h3></v-col
          >
          <v-col cols="8" xl="6" offset="2" xl-offset="4"
            ><h4 class="text-center">
              <span v-if="client.TypeClient !== undefined && client.TypeClient.typologie == 'particulier'" class="capitalize">Mr </span>
              <span v-else>{{ client.contact_privilegie }}</span>
              <span class="uppercasePopup"
                >{{ client ? client.nom : "" }}
                {{ client ? client.prenom : "" }}</span
              >
              <span class="capitalize">
                -
                {{
                  client && client.TypeClient ? client.TypeClient.typologie : ""
                }}</span
              >
            </h4></v-col
          >
          <v-col cols="8" xl="6" offset="2" xl-offset="4"
            ><h3 class="font-weight-light">
              <p class="capitalize text-center custom-pink text-h2">
                {{
                  $moment(
                    collecte && collecte.Creneau ? collecte.Creneau.date : ""
                  ).format("LL")
                }}
              </p>
            </h3></v-col
          >
           <v-col cols="8" xl="6" offset="2" xl-offset="4"
            ><h3 v-if="collecte.date_appel != null || collecte.updatedAt != null" class="font-weight-light">
              <p class="capitalize text-center custom-pink text-h5">
               appel du {{$moment(collecte && collecte.date_appel? collecte.date_appel : collecte.updatedAt).format("LLL")}}
              </p>
            </h3></v-col
          >
        </v-row>

        <v-row
          v-if="
            client &&
              client.TypeClient &&
              client.TypeClient.typologie == 'particulier'
          "
        >
          <v-col cols="8" xl="6" offset="2" xl-offset="4" align="center">
            <v-btn
              class="pa-0 detail-btn mt-3"
              text
              dark
              color="#EA7E2A"
              depressed
              link
              plain
              @click="detailsBool = !detailsBool"
              >voir le détail

              <i
                v-if="detailsBool == false"
                @click="detailsBool = !detailsBool"
                class="fas fa-caret-down"
              ></i>
              <i
                v-else
                @click="detailsBool = !detailsBool"
                class="fas fa-caret-up"
              ></i>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="details scroll" v-if="detailsBool == true">
          <v-col align="center" cols="8" offset="2" xl="6" xl-offset="3">
            <v-data-table
              hide-default-footer
              hide-default-header
              :headers="headers"
              :items="collecte.Collectibles_a_Recuperers"
              dense
            >
              <template
                v-slot:[`item.Collectible.Categorie.url_logo`]="{ item }"
              >
                <img
                  v-if="item.Collectible.Categorie.url_logo !== 'TOTAL'"
                  width="40"
                  :src="url + item.Collectible.Categorie.url_logo"
                />
                <span style="color: orange; font-weight: bold" v-else>{{
                  item.Collectible.Categorie.url_logo
                }}</span>
              </template>

              <template v-slot:[`item.Collectible.libelle`]="{ item }">
                <span class="gris">{{ item.Collectible.libelle }}</span>
              </template>

              <template v-slot:[`item.Collectible.volume`]="{ item }">
                <span v-if="item.quantite !== ''" class="gris"
                  >{{ item.Collectible.volume }}m³</span
                >
                <span v-else style="font-weight: bold"
                  >{{ item.Collectible.volume }}m³</span
                >
              </template>

              <template
                class="gris"
                v-slot:[`item.Collectible.poids`]="{ item }"
              >
                <span v-if="item.quantite !== ''" class="gris"
                  >{{ item.Collectible.poids }}kg</span
                >
                <span v-else style="font-weight: bold"
                  >{{ item.Collectible.poids }}kg</span
                >
              </template>

              <template class="gris" v-slot:[`item.quantite`]="{ item }">
                <span v-if="item.quantite !== ''" class="gris"
                  >x{{ item.quantite }}</span
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- </div> -->

        <v-row class="mt-4">
          <v-col cols="8" offset="2" xl="6" xl-offset="3">
            <p class="text-left libelle_radio">&Eacute;tat de la collecte</p>
            <v-radio-group
              hide-details
              v-if="client && client.Marche && client.Marche.marche == 'CCVE'"
              v-model="collecte.type"
            >
              <v-radio label="collecte réalisée" value="collecte réalisée"></v-radio>
              <v-radio
                label="collecte non conforme"
                value="collecte non conforme"
              ></v-radio>
              <v-radio
                label="collecte non réalisée"
                value="collecte non réalisée"
              ></v-radio>
              <v-radio
                label="collecte annulée"
                value="collecte annulée"
              ></v-radio>
              <v-radio
                label="collecte supprimée"
                value="collecte supprimée"
              ></v-radio>
            </v-radio-group>

            <v-radio-group
              hide-details
              v-if="
                client && client.Marche && client.Marche.marche == 'SIREDOM'
              "
              v-model="collecte.type"
            >
              <v-radio label="collecte réalisée" value="collecte réalisée"></v-radio>
              <v-radio
                label="collecte non réalisée non conforme"
                value="collecte non réalisée non conforme"
              ></v-radio>
              <v-radio
                label="collecte non réalisée administré absent"
                value="collecte non réalisée administré absent"
              ></v-radio>
              <v-radio
                label="collecte annulée"
                value="collecte annulée"
              ></v-radio>
              <v-radio
                label="collecte supprimée"
                value="collecte supprimée"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row
          v-if="
            collecte.type == 'collecte non réalisée' ||
              collecte.type == 'collecte non conforme' ||
              collecte.type == 'collecte non réalisée non conforme' ||
              collecte.type == 'collecte non réalisée administré absent' ||
              collecte.type == 'collecte annulée'
          "
        >
          <v-col cols="8" offset="2" xl="6" xl-offset="3">
            <v-text-field
              label="Commentaire"
              single-line
              outlined
              hide-details
              v-model="collecte.commentaire"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="client && client.Marche && client.Marche.marche == 'SIREDOM'"
        >
          <v-col cols="8" offset="2" xl="6" xl-offset="3">
            <p class="text-left libelle_radio">Carte identité / PMR</p>
            <v-radio-group hide-details v-model="collecte.possede_carte" row>
              <v-radio label="Oui" :value="true"></v-radio>
              <v-radio label="Non" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col align="center">
            <v-btn
              align="center"
              justify="center"
              color="#EA7E2A"
              class="
              rounded-lg
              white--text
              modify-btn
              font-weight-bold
            "
              @click="modifyState()"
              >valider</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- <v-card
      v-else
      class="modal_2 d-flex flex-column justify-center align-center"
      light
    >
      <img
        id="close"
        src="@/assets/close.png"
        @click="$emit('close'), (show2 = false)"
      />
      <h3 class="mt-5 title uppercasePopup">
        Collecte modifiée
      </h3>
    </v-card> -->
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </v-overlay>
</template>

<script>
import apiCollecte from "@/api/collectes";

export default {
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      show2: false,
      detailsBool: false,
      headers: [
        {
          text: "Catégorie",
          value: "Collectible.Categorie.url_logo",
          name: "url_img",
        },
        { text: "Libellé du produit", value: "Collectible.libelle" },
        { text: "Volume", value: "Collectible.volume" },
        { text: "Poids", value: "Collectible.poids" },
        { text: "quantite", value: "quantite" },
      ],
      products: [],
      color: "#93b122",
      errors: [],
      timeout: 2000,
      snackbar: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    collecte: {
      type: Object,
      default: () => {},
    },
    client: {
      type: Object,
      default: () => {},
    },
    info: {
      type: String,
      default: () => "",
    },
  },

  methods: {
    modifyState() {
      let data = {
        type: this.collecte.type,
        commentaire: this.collecte.commentaire,
        possede_carte: this.collecte.possede_carte,
      };

      apiCollecte
        .modifyCollecteState(this.collecte.id_collecte, data)
        .then((res) => {
          console.log(res.data);
          this.snackbar = true;
          this.errors = [];
          this.errors.push(" La collecte a été modifiée");
          setTimeout(() => {
            this.$emit("close");
          }, this.timeout);
        });

      console.log("collecte state obj : ", data);
    },
  },

  created() {},
};
</script>

<style scoped>
.v-card {
  width: 58.71vw;
  max-width: 1088px;

  max-height: 88vh;
  position: relative;
}
.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 85vh;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
  max-width: 65px;
}

.title {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ea7e2a;
  display: flex;
  justify-content: center;
}

h4 {
  font-size: 2.1rem;
  font-weight: bold;
}

.custom-pink {
  color: #ea7e2a;
}

.uppercasePopup {
  text-transform: uppercase;
}
.carre_img_1 {
  border: 1px solid;
  border-radius: 5px;
  border-color: #e0e0e0;
  margin-right: 10px;
  margin-top: 10px;
  height: 100%;
}

.modal_2 {
  width: 58.71vw;
  max-width: 1088px;
  height: auto;
  border-radius: 11px;
}

.detail-btn i {
  padding-left: 0.5rem;
  text-decoration: none;
}
.detail-btn {
  border-bottom: 1px solid #ea7e2a;
  border-radius: 0;
  height: 20px !important;
}

#route
  > div
  > div.row.justify-center
  > div.fullheight.col.col-3
  > div.nextCollect.v-card.v-sheet.theme--light
  > div.v-overlay.v-overlay--active.theme--dark
  > div.v-overlay__content
  > div
  > button {
  font-size: 1.5rem;
  padding: 0 170px;
}

::v-deep tbody tr:nth-child(2n) {
  background-color: #f8f8f8;
}
.infor {
  font-size: 2rem;
}

::v-deep td {
  border-top: 1px solid;
}

::v-deep tr:last-of-type > td {
  border-top: 1px solid #ea7e2a;
}

::v-deep .gris {
  font-weight: bold;
  color: #737373;
}

::v-deep .orange {
  font-weight: bold;
  color: #ea7e2a;
}

.libelle_radio {
  text-decoration: underline;
  font-weight: bold;
  color: #ea7e2a;
}


@media screen and (max-width: 1080px) {
  .details {
    width: 70%;
  }
}

@media screen and (max-width: 810px) {
  .details {
    width: 70%;
  }
}
</style>
