<template>
  <v-card id="contact">
    <v-row no-gutters>
      <v-col xl="9" lg="8" md="9" cols="9">
        <h2
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
        >
          INFORMATIONS DE CONTACT
        </h2>
        <h2 v-else class="contact-title">INFORMATIONS DE CONTACT</h2>
        <div
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          class="line"
        ></div>
        <div v-else class="line-blue"></div>

        <!-- Particulier -->
        <v-row
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          no-gutters
          class="tal mt-7"
          align="center"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles"
            ><p class="">CIVILITE</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize">{{ client.civilite }}</p></v-col
          >
        </v-row>
        <v-row
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          no-gutters
          class="tal mt-2 "
          align="center"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles"
            ><p class="">NOM</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize maxi">{{ client.nom }}</p></v-col
          >
        </v-row>
        <v-row
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          no-gutters
          class="tal mt-2"
          align="center"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles">
            <p class="">PR&Eacute;NOM</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize maxi">{{ client.prenom }}</p></v-col
          >
        </v-row>
        <v-row
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          no-gutters
          class="tal mt-3"
          align="center"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles">
            <p class="">DATE DE NAISSANCE</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize">
              <span v-if="client.date_naissance != null"
                >{{ $moment(client.date_naissance).format("DD/MM/YYYY") }} ({{
                  $moment().diff(client.date_naissance, "years")
                }}
                ans)</span
              >
              <span v-else>non renseignée </span>
            </p></v-col
          >
        </v-row>
        <v-row
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          no-gutters
          class="tal mt-3"
          align="center"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles">
            <p class="">PMR</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize">
              {{ client.carte_handicap == true ? "Oui" : "Non" }}
            </p></v-col
          >
        </v-row>

        <!-- <v-row
          v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'"
          no-gutters
          class="tal mt-7"
        >
          <v-col xl="4" lg="6" md="4" cols="3" class="titles">
            <v-row no-gutters class="h"> <p class="">CIVILITE</p></v-row>
            <v-row no-gutters class="th" align="center">
              <p class="">NOM</p></v-row
            >
            <v-row no-gutters class="th" align="center">
              <p class="">PR&Eacute;NOM</p></v-row
            >
            <v-row no-gutters class="h">
              <p class="">DATE DE NAISSANCE</p></v-row
            >
            <v-row no-gutters class="h"> <p class="">PMR</p></v-row>
          </v-col>
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <v-row no-gutters class="h">
              <p class="capitalize">{{ client.civilite }}</p></v-row
            >
            <v-row no-gutters class="th"
              ><p class="capitalize maxi">{{ client.nom }}</p></v-row
            >
            <v-row no-gutters class="th"
              ><p class="capitalize maxi">{{ client.prenom }}</p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">
                <span v-if="client.date_naissance != null"
                  >{{ $moment(client.date_naissance).format("DD/MM/YYYY") }} ({{
                    $moment().diff(client.date_naissance, "years")
                  }}
                  ans)</span
                >
              </p></v-row
            >
            <v-row no-gutters class="h">
              <p class="capitalize">
                {{ client.carte_handicap == true ? "Oui" : "Non" }}
              </p>
            </v-row>
          </v-col>
        </v-row> -->

        <!-- Bailleur -->
        <v-row no-gutters class="tal">
          <v-col xl="11" lg="11" md="11" cols="11" class="titles">
            <v-row
              v-if="
                client.TypeClient && client.TypeClient.typologie == 'bailleur'
              "
              no-gutters
              class="tal mt-2"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles"
                ><p class="">BAILLEUR</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize maxi ml-7">{{ client.bailleur }}</p></v-col
              >
            </v-row>

            <v-row
              v-if="
                client.TypeClient && client.TypeClient.typologie == 'bailleur'
              "
              no-gutters
              class="tal mt-2"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles"
                ><p class="">CONTACT PRIVILÉGIÉ</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize maxi ml-7">
                  {{ client.contact_privilegie }}
                </p></v-col
              >
            </v-row>

            <v-row
              v-if="
                client.TypeClient && client.TypeClient.typologie == 'bailleur'
              "
              no-gutters
              class="tal mt-2"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles"
                ><p class="">DATE DE NAISSANCE</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize ml-7">
                  {{ $moment(client.date_naissance).format("DD/MM/YYYY") }} ({{
                    $moment().diff(client.date_naissance, "years")
                  }}
                  ans)
                </p></v-col
              >
            </v-row>

            <v-row
              v-if="
                client.TypeClient && client.TypeClient.typologie == 'bailleur'
              "
              no-gutters
              class="tal mt-2"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles"
                ><p class="">PMR</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize ml-7">
                  {{ client.carte_handicap == true ? "Oui" : "Non" }}
                </p></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="3">
        <div
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
        >
          <img
            v-if="!client.blacklisted"
            src="../../../assets/images/clients/bonhommeVert.png"
            alt="Logo particulier"
          />
          <img
            v-if="client.blacklisted"
            src="../../../assets/images/clients/bonhommeRouge.png"
            alt="Logo particulier"
          />
        </div>

        <div v-else>
          <img
            v-if="!client.blacklisted"
            src="../../../assets/images/clients/logo-bailleur.png"
            alt="Logo bailleur"
          />
          <img
            v-if="client.blacklisted"
            src="../../../assets/images/clients/logo-bailleur-rouge.png"
            alt="Logo bailleur"
          />
        </div>
        <p class="uppercase">
          {{ client.TypeClient ? client.TypeClient.typologie : "" }}
        </p>
        <v-btn
          v-if="!client.blacklisted"
          @click="
            showPopup = true;
            title = 'Ajouter à la liste noire';
            btn = 'Ajouter à la liste noire';
          "
          width="75%"
          rounded
          class="blackList"
          color="#AF2C70"
          large
          dark
          depressed
        >
          Ajouter à la liste noire
        </v-btn>
        <v-btn
          v-if="client.blacklisted"
          @click="
            showPopup = true;
            title = 'Retirer de la liste noire';
            btn = 'Retirer de la liste noire';
          "
          width="75%"
          rounded
          class="blackList"
          color="#AF2C70"
          large
          dark
          depressed
        >
          Retirer de la liste noire
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div
        v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'"
        class="line w100 mt10"
      ></div>
      <div v-else class="line-blue w100 mt10"></div>
    </v-row>
    <v-row no-gutters class="tal">
      <v-col xl="9" lg="8" md="9" cols="9">
        <v-row no-gutters class="tal mt-2" align="center">
          <v-col xl="4" lg="6" md="4" cols="3" class="titles"
            ><p class="">MARCHÉ</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize maxi">
              {{ client && client.Marche ? client.Marche.marche : "" }} -
              {{
                client && client.Marche && client.Marche.Secteurs.length >= 0
                  ? client.Marche.Secteurs[0].nom_secteur
                  : ""
              }}
            </p></v-col
          >
        </v-row>

        <v-row no-gutters class="tal mt-2" align="center">
          <v-col xl="4" lg="6" md="4" cols="3" class="titles"
            ><p class="">NUMÉRO D'ADHÉRENT</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize">{{ client.numero_adherent }}</p></v-col
          >
        </v-row>

        <v-row no-gutters class="tal mt-2" align="center">
          <v-col xl="4" lg="6" md="4" cols="3" class="titles"
            ><p class="">TYPE</p></v-col
          >
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <p class="capitalize">
              {{
                client && client.TypeClient ? client.TypeClient.typologie : ""
              }}
            </p></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div
        v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'"
        class="line w100 mt10"
      ></div>
      <div v-else class="line-blue w100 mt10"></div>
    </v-row>
    <v-row no-gutters class="tal">
      <v-col xl="9" lg="8" md="9" cols="9">
        <v-row no-gutters>
          <v-col xl="4" lg="6" md="4" cols="3" class="titles">
            <v-row no-gutters class="h"> <p class="">ADRESSE</p></v-row>
            <v-row no-gutters class="h" align="center">
              <p class="">COMPL&Eacute;MENT D'ADRESSE</p></v-row
            >
            <v-row no-gutters class="h" align="center">
              <p class="">CODE POSTAL</p></v-row
            >
            <v-row no-gutters class="h" align="center">
              <p class="">VILLE</p></v-row
            >
          </v-col>
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <v-row no-gutters class="h">
              <p class="capitalize">{{ client.adresse }}</p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">{{ client.complement_adresse }}</p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">{{ client.code_postal }}</p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">{{ client.ville }}</p></v-row
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div
        v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'"
        class="line w100 mt10"
      ></div>
      <div v-else class="line-blue w100 mt10"></div>
    </v-row>

    <v-row no-gutters class="tal">
      <v-col xl="9" lg="8" md="9" cols="9">
        <v-row no-gutters>
          <v-col  class="titles">
            <v-row
            
              no-gutters
              class="tal mt-3"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles">
                <p class="">INFORMATIONS UTILES</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize">
                  {{ client.informations }}
                </p></v-col
              >
            </v-row>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <v-col  class="titles">
            <v-row
            
              no-gutters
              class="tal mt-3"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles">
                <p class="">T&Eacute;L&Eacute;PHONE FIXE</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize">
                  {{
                  client.telephone_fixe
                    ? client.telephone_fixe
                        .replace("+33", "0")
                        .replace(/(\d\d(?!$))/g, "$1" + " ")
                    : ""
                }}
                </p></v-col
              >
            </v-row>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <v-col  class="titles">
            <v-row
            
              no-gutters
              class="tal mt-3"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles">
                <p class="">T&Eacute;L&Eacute;PHONE MOBILE</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize">
                  {{
                  client.telephone_mobile
                    ? client.telephone_mobile
                        .replace("+33", "0")
                        .replace(/(\d\d(?!$))/g, "$1" + " ")
                    : ""
                }}
                </p></v-col
              >
            </v-row>
          </v-col>
        </v-row>



        <v-row no-gutters>
          <v-col  class="titles">
            <v-row
            
              no-gutters
              class="tal mt-3"
              align="center"
            >
              <v-col xl="4" lg="6" md="4" cols="3" class="titles">
                <p class="">EMAIL</p></v-col
              >
              <v-col xl="8" lg="6" md="8" cols="9" class="value">
                <p class="capitalize">
                  {{ client.email }}
                </p></v-col
              >
            </v-row>
          </v-col>
        </v-row>








            <!-- <v-row no-gutters class="h">
              <p class="">INFORMATIONS UTILES</p></v-row
            >
            <v-row no-gutters class="h" align="center">
              <p class="">T&Eacute;L&Eacute;PHONE FIXE</p></v-row
            >
            <v-row no-gutters class="h" align="center">
              <p class="">T&Eacute;L&Eacute;PHONE MOBILE</p></v-row
            >
            <v-row no-gutters class="h" align="center">
              <p class="">EMAIL</p></v-row
            >
          </v-col>
          <v-col xl="8" lg="6" md="8" cols="9" class="value">
            <v-row no-gutters class="h">
              <p class="capitalize">{{ client.informations }}</p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">
                {{
                  client.telephone_fixe
                    ? client.telephone_fixe
                        .replace("+33", "0")
                        .replace(/(\d\d(?!$))/g, "$1" + " ")
                    : ""
                }}
              </p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">
                {{
                  client.telephone_mobile
                    ? client.telephone_mobile
                        .replace("+33", "0")
                        .replace(/(\d\d(?!$))/g, "$1" + " ")
                    : ""
                }}
              </p></v-row
            >
            <v-row no-gutters class="h"
              ><p class="capitalize">{{ client.email }}</p></v-row
            >
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <v-row class="mt-12 mb-3" no-gutters v-if="$route.name != 'collectes'">
      <v-col>
        <v-btn
          v-if="
            client.TypeClient && client.TypeClient.typologie == 'particulier'
          "
          class="round"
          color="#93b121"
          block
          large
          dark
          depressed
          @click="
            $router.push({
              name: 'updateClient',
              params: { id_client: client.id_client },
            })
          "
        >
          MODIFIER DES INFORMATIONS
        </v-btn>
        <v-btn
          v-else
          class="round"
          color="#0084c5"
          block
          large
          dark
          depressed
          @click="
            $router.push({
              name: 'updateClient',
              params: { id_client: client.id_client },
            })
          "
        >
          MODIFIER DES INFORMATIONS
        </v-btn>
         <v-btn
          class="round mt-4"
          color="#D61111"
          block
          large
          dark
          depressed
          @click="showConfirm = true"
        >
          SUPPRIMER LA FICHE
        </v-btn>
      </v-col>

      <div id="snack">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="#93b122"
        elevation="24"
        class="text-center"
      >
        {{ message }}
      </v-snackbar>
    </div>
    </v-row>
    <popup
      :show="showPopup"
      :title="title"
      :client="client"
      :commentaire="commentaire"
      :btn="btn"
      @backlist="blackList"
      @close="showPopup = false"
      @errorFields="errorFields"
    ></popup>
    <confirmPop
      :show="showConfirm"
      :title="titleConfirm"
      :btn="btnConfirm"
      @close="showConfirm = false"
      @deleteFiche="deleteFiche"
      @closeConfirmPop="closeConfirmPop"
    ></confirmPop>
  </v-card>

</template>
<script>
import popup from "../../popups/popup.vue";
import confirmPop from "../../popups/confirmPop.vue";
import apiClient from "../../../api/clients";

export default {
  components: { popup, confirmPop },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopup: false,
      title: "Ajouter à la liste noire",
      commentaire: "",
      btn: "Ajouter à la liste noire",
      active: 1,
      titleConfirm: "Voulez-vous vraiment supprimer cette fiche ?",
      showConfirm: false,
      btnConfirm: [
        { label: "Oui", action: "deleteFiche" },
        { label: "Non", action: "closeConfirmPop" }
      ],
      message: "",
      snackbar: false,
      timeout: 2000
    };
  },
  methods: {
    blackList(value) {
      this.$emit("blackList", value);
      this.showPopup = false;
      this.commentaire = "";
      console.log("commmmm", this.commentaire);
    },
    errorFields(value) {
      console.log("emit dans commentaire");
      this.$emit("errorFields", value);
    },
    deleteFiche() {
      apiClient.desactiveClient(this.client.id_client).then(res => {
        console.log(res);
        this.message = "La fiche client a bien été supprimée !";
        this.snackbar = true;
        setTimeout(this.go, this.timeout);
      })
    },
    closeConfirmPop() {
      this.showConfirm = false
    },
    go() {
      this.$router.push({ name: 'clients' });
    }
  },
};
</script>
<style scoped>
@import "./contact.css";
</style>
