<template>
  <div>
    <v-overlay :value="showSecteurPop">
 <v-card class="rounded-lg "  :class="frequence.length > 0 ? 'heightCreateCreneau' : '' " light>
        <img id="close" src="@/assets/close.png" @click="$emit('close')" />
        <h3 class="py-5 text-uppercase">{{ titleSecteur }}</h3>

        <div class="mt-10">
          <ValidationObserver ref="form">
            <form id="form" >


                <v-row no-gutters class="ml-0 mb-3">
                  <v-col cols="2"></v-col>
                  <v-col cols="8" >
                    <ValidationProvider
                      :name="fields[0].name"
                      :rules="fields[0].rules"
                    >
                      <v-select
                      class="rounded-lg"
                        outlined
                        :items="days"
                        item-value="jour"
                        item-text="name"
                        v-model="fields[0].value"
                        :placeholder="fields[0].placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>


                  <!-- date debut - date fin -->
                <v-row no-gutters class="ml-0 mb-0 " style="height: 71px;">
                  <v-col cols="2"></v-col>
                  <v-col cols="4" >

                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="fields[1].value"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      :name="fields[1].name"
                      :rules="fields[1].rules"
                    >
                          <v-text-field
                            style="width: 96%"
                            class="rounded-lg"
                            :value="fields[1].value"
                            v-model="dateDebutComputed"
                            :placeholder="fields[1].placeholder"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            hide-details
                          ></v-text-field>
                    </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="fields[1].value"
                          no-title
                          locale="fr-fr"
                          :min="$moment().toISOString()"
                          @change="$refs.menu.save(fields[1].value);"
                          @input=" menu = false"
                        >
                        </v-date-picker>
                      </v-menu>

                  </v-col>

                  
                  <v-col cols="4" >

                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="fields[2].value"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                      :name="fields[2].name"
                      :rules="fields[2].rules"
                    >
                          <v-text-field
                                                   style="width: 97%"
                            class="rounded-lg ml-2"
                            :value="fields[2].value"
                            v-model="dateFinComputed"
                            :placeholder="fields[2].placeholder"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            hide-details
                          ></v-text-field>
                     </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="fields[2].value"
                          no-title
                          locale="fr-fr"
                          :min="$moment().toISOString()"
                          @change="$refs.menu2.save(fields[2].value);"
                          @input=" menu2 = false"
                        >
                        </v-date-picker>
                      </v-menu>


                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>

               <!-- heure debut - heure fin -->
                <v-row no-gutters class="ml-0" style="height: 71px;">
                  <v-col cols="2"></v-col>
                  <v-col cols="4" >

                                        <v-menu
                       ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="fields[3].value"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >

                      <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      :name="fields[3].name"
                      :rules="fields[3].rules"

                    >
                        <v-text-field
                          style="width: 96%"
                          class="rounded-lg "
                          outlined
                          v-model="fields[3].value"
                          :placeholder="fields[3].placeholder"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </ValidationProvider>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="menu1"
                        v-model="fields[3].value"
                        full-width
                        @click:minute="$refs.menu1.save(fields[3].value); menu1 = false;"
                      ></v-time-picker>
                    </v-menu>



                  </v-col>

                  
                  <v-col cols="4" class="ml-1">
                                        <v-menu
                       ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="fields[4].value"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >

                      <template v-slot:activator="{ on, attrs }">
                                            <ValidationProvider
                      :name="fields[4].name"
                      :rules="fields[4].rules"

                    >
                        <v-text-field
                          style="width: 97%"
                          class="rounded-lg ml-1"
                          outlined
                          v-model="fields[4].value"
                          :placeholder="fields[4].placeholder"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                                          </ValidationProvider>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="menu3"
                        v-model="fields[4].value"
                        full-width
                        @click:minute="$refs.menu3.save(fields[4].value); menu3 = false;"
                      ></v-time-picker>
                    </v-menu>

                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>


                <v-row no-gutters class="ml-0 ">
                  <v-col cols="2"></v-col>
                  <v-col cols="8" >
                    <ValidationProvider
                      :name="fields[5].name"
                      :rules="fields[5].rules"
                    >
                      <v-select
                        class="rounded-lg"
                        outlined
                        :items="frequence"
                        item-value="id_occurence"
                        item-text="occurence"
                        v-model="fields[5].value"
                        :placeholder="fields[5].placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>


                <v-row no-gutters  class="ml-0 mt-3 ">
                  <v-col cols="2"></v-col>
                  <v-col cols="8"  id="">
                    <ValidationProvider
                      :name="fields[6].name"
                      :rules="fields[6].rules"
                    >
                      <v-text-field
                        class="rounded-lg"
                        v-model.number="fields[6].value"
                        outlined
                        :readonly="fields[6].readonly ? fields[6].readonly : false"
                        :placeholder="fields[6].placeholder"
                        hide-details
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="8"></v-col>
                </v-row>


                        <v-spacer style="height: 60px"></v-spacer>
          <v-row no-gutters  class="ml-0 mt-3 ">
            <v-col cols="3"></v-col>
              <v-col cols="12" >
                <v-btn
                  width="400"
                  color="#af2c70"
                  class="rounded-lg text-uppercase font-weight-bold"
                  dark
                  x-large
                  @click="submit('createCreneau')"
                >
                Créer le créneau 
                </v-btn>
              </v-col>
            <v-col cols="3"></v-col>
          </v-row>


            </form>

          </ValidationObserver>
          

        </div>
      </v-card>
    </v-overlay>

  </div>

  
</template>

<script>
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuCalendar: false,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      foos: [
        { id: 1, date: new Date() },
        { id: 2, date: new Date() },
      ],
    };
  },

  props: {
    categorie: {
      type: Object,
      default: () => {},
    },
    showSecteurPop: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    camions: {
      type: Array,
      default: () => [],
    },
    btn2: {
      type: Array,
      default: () => [],
    },
    titleSecteur: {
      type: String,
      default: () => "",
    },
    roles: {
      type: Array,
      default: () => [],
    },
    days: {
      type: Array,
      default: () => [],
    },
    frequence: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
      dateDebutComputed () {
        if ( this.fields[1].value != "" ) {
          return this.$moment(this.fields[1].value).format("DD/MM/YYYY")
        } else {
          return ""
        }
      },
      dateFinComputed () {
        if ( this.fields[2].value != "" ) {
          return this.$moment(this.fields[2].value).format("DD/MM/YYYY")
        } else {
          return ""
        }
      },
    // computedDateFormattedMomentjs : {
    //   cache:false,
    //   get(){
    //   let date = this.fields[3].value
    //   for ( var i=0; i < date.length; i++) {
    //     // date[i] = this.$moment(date[i]).format("DD/MM/YYYY")
    //   }
    //   date = date[0] + ' - ' + date[1]
    //   return date;
    //   }

    // },
  },
  methods: {
    submit(method) {
      let errors = [];
              let e = this.$refs.form.errors;
      this.$refs.form.validate().then((valid) => {

        if (valid == true) {
          this.$emit(method, { valid: valid, errors: errors, e: e });
        } else {
          //display error
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              errors.push(msg);
            });
          }
          console.error('error', errors);
          this.color = "red darken-2";
          this.errors = errors
          // this.snackbar = true
          console.log(this.snackbar, "snackbar else")
          this.$emit(method, { valid: valid, errors: errors, e: e });
        }
        //display error
        console.log(valid);

        // for (const field in e) {
        //   e[field].forEach((msg) => {
        //     errors.push({ msg: msg, id: Math.random() * 100 });
        //   });
        // }


      });
    },
  },


};
</script>

<style scoped>
/* .v-card {
  width: 58.71vw;
  max-width: 1088px;
  max-height: 700px;
  position: relative;
} */
::v-deep .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
    height: auto;
    min-height: 56px;
}

.heightCreateCreneau {

    width: 58.71vw;
  max-width: 1088px;
  max-height: 700px;
  position: relative;

  height: 800px !important;
  max-width: 2000px;
}

::v-deep .v-text-field input::placeholder {
      font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

::v-deep .mdi-menu-down::before {
  content: "\F35D";
  color: #333333;
  font-size: 70px;
}

#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

#close {
  cursor: pointer;
}
.rounded-lg.v-card.v-sheet.theme--light {
  border-radius: 17px !important;
}

::v-deep .v-input__slot {
  height: 56px;
}

#form .v-input {
  border: none !important;
}

#form ::v-deep .v-text-field input::placeholder {
  font-style: italic;
}
</style>
