import axios from "axios";

const RESSOURCE_NAME = "camions";

export default {
    getCamions() {
        return axios.get(`${RESSOURCE_NAME}`)
    },

    createCamion(camion) {
        return axios.post(`${RESSOURCE_NAME}/create`, { camion: camion })
    },

    deleteCamion(id_camion) {
        return axios.delete(`${RESSOURCE_NAME}/${id_camion}`)
    }
};