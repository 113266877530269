<template>
  <v-main>
    <v-row>
      <v-col cols="12">
        <v-row
          align="center"
          class="row-grey font-weight-bold"
          v-for="camion in camions"
          :key="camion.titre"
          no-gutters
        >
          <v-col justfiy="center" cols="1">
            <img
              width="50"
              height="50"
              src="@/assets/images/administration/flotte-rose.png"
              alt="Logo camion"
            />
          </v-col>

          <v-col align="start" justfiy="center" cols="2">
            <p class="mt-3">{{ camion.titre }}</p>
          </v-col>

          <v-col justfiy="center" cols="2">
            <p class="mt-3">{{ camion.reference }}</p>
          </v-col>

          <v-col align="start" justfiy="center" cols="2">
            <p class="mt-3">{{ camion.modele }}</p>
          </v-col>

          <v-col align="start" justfiy="center" cols="2">
            <p class="mt-3 ml-3">{{ camion.volume }}</p>
          </v-col>

          <v-row align="center">
            <v-col
              justfiy="center"
              v-for="button in btn"
              :key="button.name"
              cols="3"
            >
              <v-btn
                class="text-decoration-underline text-lowercase"
                text
                color="#AF2C70"
                @click=" showDeleteCamion = true"
                v-if="button.method == 'deleteTruck'"
              >
                {{ button.name }}
              </v-btn>
            </v-col>
          </v-row>

            <v-overlay :value="showDeleteCamion">
          <v-card
        v-if="showDeleteCamion == true"
        class="modal_2 justify-center align-center"
        light
      >
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5 title uppercasePopup">
              Supprimer ce camion ?
            </h3>
          </v-col>
          <v-col cols="4" offset="2">
            <v-btn
              v-on:click="
                deleteTruck(camion),
                  (showDeleteCamion = false)
              "
              color="success"
              x-large
              >oui</v-btn
            ></v-col
          >
          <v-col cols="4" @click="show2 = false"
            ><v-btn v-on:click="showDeleteCamion = false" color="error" x-large
              >non</v-btn
            ></v-col
          >

        </v-row>
      </v-card>
  </v-overlay>
        </v-row>

        <v-col align="left" cols="3">
          <v-btn
            color="#cc0000"
            dark
            class=" my-3 font-weight-bold rounded-xl"
            @click="show = true"
          >
            Ajouter un camion
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error.id"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error.msg }}
      </v-snackbar>
    </div>

    <FlottePop
      :fields="fields"
      :btn2="btn"
      :showSecteurPop="show"
      :titleSecteur="title"
      @addTruck="addTruck"
      @close="show = false"
    />


  </v-main>
</template>

<script>
import FlottePop from "@/components/popups/secteurPop";
import apiCamions from "@/api/camions";

export default {
  name: "flotte",

  data() {
    return {
      camions: [],
      showDeleteCamion: false,
      show: false,
      errors: [],
      color: "",
      timeout: 3000,
      fields: [
        {
          label: "titre",
          rules: "required",
          value: "",
          placeholder: "Nom du camion *",
          type: "text",
        },
        {
          label: "model",
          rules: "required",
          value: "",
          placeholder: "Marque *",
          type: "text",
        },
        {
          label: "immatriculation",
          rules: "required",
          value: "",
          placeholder: "Immatriculation *",
          type: "text",
        },
        {
          label: "volume",
          rules:{
            required: true,
            regex: /^\d{1,10}([.,]\d{1,4})?$/,
          },
          value: "",
          placeholder: "Cubage *",
          type: "text",
        },
      ],
      btn: [
        { name: "Créer le nouveau camion", method: "addTruck" },
        { name: "supprimer", method: "deleteTruck" },
      ],
      title: "AJOUTER UN CAMION",
    };
  },

  components: {
    FlottePop,
  },

  props: {},

  methods: {
    getAllCamions() {
      apiCamions.getCamions().then((res) => {
        this.camions = res.data.camions;
        console.log("liste camions ", this.camions);
      });
    },

    addTruck(error) {
      this.snackbar = false;
      console.log("hey add truck");
      if (error.valid) {
        let data = {};
        this.fields.forEach((field) => {
          data[field.label] = field.value;
        });
        data.volume = data.volume.replace(",", ".")
        console.log("data camion", data.volume)
        apiCamions
          .createCamion(data)
          .then(() => {
            this.color = "#93b122";
            this.errors = [];
            this.errors.push({msg:`Le camion ${this.fields[0].value} a été créé`,id:Math.random() * 100});
            this.snackbar = true;
            this.showSecteurPop = false;
            this.getAllCamions();
            this.show = false;

            this.fields.forEach((field) => {
              field.value = null;
            });
          })
          .catch((err) => {
            if (err.response.status == 500) {
              this.color = "red darken-2";
              this.errors.push(`Impossible de créer le camion`);
              this.snackbar = true;
            }
          });
      } else {
        console.log("hey else");
        this.color = "red darken-2";
        this.errors = [];
        this.errors = error.errors;
        this.snackbar = true;
        console.log(this.snackbar, this.errors);
      }
    },

    deleteTruck(camion) {
      apiCamions.deleteCamion(camion.id_camion).then(() => {
        this.color = "#93b122";
        this.errors.push({msg:`Le camion a bien été supprimé !`,id:Math.random() * 100});
        this.snackbar = true;
        this.getAllCamions();
      });
    },
  },

  created() {
    this.getAllCamions();
  },
};
</script>

<style scoped>
.row-grey {
  height: 80px;
}

.row-grey:nth-child(2n) {
  background-color: #f8f8f8;
}
</style>
