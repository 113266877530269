<template>
  <v-main full-width class="mt-2 planning_admin">
    <v-row class="mr-4">
      <v-col cols="12">
        <p class="title ml-6 pt-4">PLANNING</p>
        <div class="line ml-6"></div>
      </v-col>


      <!-- boucle a retirer  -->
      <v-col cols="12" v-for="(week, i) in datas" :key="i">
        <v-row class="mt-10 ml-10 mr-10">
          <v-col style="display: contents">
            <div class="pl-2 pr-2 font-weight-bold text-decoration-underline" style="cursor: pointer"
              @click="getPreviousWeek()">semaine {{ previousWeek }}</div>
          </v-col>

          <v-col class="black_line ml-4"></v-col>
          <v-col style="display: contents">
            <div class="pl-2 pr-2  font-weight-bold" style="font-size: 1.3rem">semaine {{ week.week }}</div>
          </v-col>

          <v-col class="black_line mr-4"> </v-col>
          <v-col style="display: contents">
            <div class="pl-2 pr-2 font-weight-bold text-decoration-underline" style="cursor: pointer"
              @click="getNextWeek()">semaine {{ nextWeek }}</div>
          </v-col>
        </v-row>
        <!-- ligne ----- semaine 1 ----- -->
        <v-row no-gutters class="ml-10 mt-4">
          <!-- les jours  -->
          <v-col v-for="(day, i) in week.dates" :key="i" class="ml-0 mr-0">
            <v-row no-gutters
              :class="[(day.creneau[0] != undefined ? day.creneau[0].compteur >= 0 : '' || day.creneau[1] != undefined ? day.creneau[1].compteur >= 0 : ''
                && $moment(day.creneau[0] != undefined ? day.creneau[0].date : '').isAfter($moment().add(-1, 'days'))) || $moment(day.creneau[1] != undefined ? day.creneau[1].date : '').isAfter($moment().add(-1, 'days')) ? '' : 'flou', 'size']">
              <v-col cols="12" class="date_calendar d-flex flex-column align-center"
                style="box-shadow: 0px 0px 5px grey">
                <p class="mb-0 mt-4" style="font-weight: bold">
                  {{ $moment(day.date).format("ddd") }}
                </p>
                <p>{{ $moment(day.date).format("DD/MM") }}</p>
                <!-- {{$moment(day.creneau[0].date).isAfter($moment().add(-1, 'days'))}}
                {{day.creneau[0].compteur >= 0 }} -->
              </v-col>

              <v-col cols="12" class="">
                <v-row no-gutters>

                  <!-- matin -->
                  <div class="w100 matin" v-for="(creneau, i) in day.creneau" :key="i">
                    <div v-if="creneau.Secteur">
                      <v-col v-if="
                        day.creneau != 'AM' &&
                        $moment(creneau.heure_debut, 'hh:mm').isBefore(
                          $moment('12:00:00', 'hh:mm')
                        ) &&
                        $moment(creneau.date).isAfter(
                          $moment().add(-1, 'days')
                        )
                      " @click="
                          goToDashboard(creneau);
                        " cols="12" :class="[
                          creneau != 'AM' && $moment(creneau.heure_debut, 'hh:mm').isBefore($moment('12:00:00', 'hh:mm')) && $moment(creneau.date).isAfter($moment().add(-1, 'days'))
                            ? 'creneau'
                            : 'noCreneau',
                          creneau.Secteur.Marche.marche ? getCreneauColor(creneau) : ''
                        ]"
                        v-bind:style="getCreneauColor(creneau) == 'place' || getCreneauColor(creneau) == 'place_siredom' ? { backgroundColor: creneau.Secteur.color } : 'border: none;'">
                        <span v-if="
                          $moment(creneau.date).isAfter(
                            $moment().add(-1, 'days')
                          ) &&
                          creneau != 'AM' &&
                          creneau.compteur > 0 &&
                          creneau.heure_debut < '12:00:00'
                        ">{{ creneau.compteur }}/{{ creneau.max_collecte }}
                        </span>
                      </v-col>
                    </div>
                    <v-col cols="12" class="noCreneau" v-if="creneau == 'AM' || $moment(creneau.date).isBefore(
                      $moment().add(-1, 'days'))">
                    </v-col>
                  </div>
                  <!-- apres midi -->
                  <div class="w100" v-for="(creneau, i) in day.creneau" :key="i">
                    <v-col v-if="
                      creneau != 'PM' &&
                      $moment('12:00:00', 'hh:mm').isBefore(
                        $moment(creneau.heure_debut, 'hh:mm')
                      ) &&
                      $moment(creneau.date).isAfter(
                        $moment().add(-1, 'days')
                      )
                    " @click="
                        goToDashboard(creneau);
                      " cols="12" :class="[
                        creneau != 'PM' &&
                          $moment('12:00:00', 'hh:mm').isBefore(
                            $moment(creneau.heure_debut, 'hh:mm')
                          ) &&
                          $moment(creneau.date).isAfter($moment().add(-1, 'days'))
                          ? 'creneau'
                          : 'noCreneau',
                        creneau.Secteur.Marche.marche ? getCreneauColor(creneau) : ''
                      ]">
                      <span v-if="
                        $moment(creneau.date).isAfter(
                          $moment().add(-1, 'days')
                        ) &&
                        creneau != 'PM' &&
                        creneau.compteur > 0 &&
                        creneau.heure_debut > '12:00:00'
                      ">{{ creneau.compteur }}/{{ creneau.max_collecte }}
                      </span>
                    </v-col>
                    <v-col cols="12" class="noCreneau" v-if="creneau == 'PM'">
                    </v-col>
                  </div>
                  <!-- <span>{{day.creneau}}</span> -->
                </v-row>


                <!-- double -->
                <v-row v-if="day.creneau[0] == undefined" no-gutters>

                  <!-- matin -->
                  <div class="w100 matin">


                    <v-col cols="12" class="noCreneau">
                      <span>
                      </span>
                    </v-col>

                  </div>
                  <!-- apres midi -->
                  <div class="w100">
                    <v-col cols="12" class="noCreneau">
                      <span>
                      </span>
                    </v-col>

                  </div>
                  <!-- <span>{{day.creneau}}</span> -->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>

      <v-row>
        <v-col cols="3" md="3" sm="6">
          <div class="ml-13 text-left">
            <div>
              <div class='box mr-1' style="background-color: #f8a5c2;"></div><span style="font-size: 1rem"> CCVE -
                Secteur particulier 1</span>
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #e77f67;"></div><span style="font-size: 1rem"> CCVE -
                Secteur particulier 2</span>
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #b8e994;"></div><span style="font-size: 1rem"> CCVE -
                Secteur collectif 3</span>
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #778beb;"></div><span style="font-size: 1rem"> CCVE -
                Secteur collectif 4</span>
            </div>
          </div>
        </v-col>

        <v-col cols="4" md="4" sm="6">
          <div class="ml-13 text-left">
            <div>
              <div class='box mr-1' style="background-color: #71abf0;"></div> SIREDOM - Secteur bleu
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #5ddda3;"></div> SIREDOM - Secteur vert
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #ff9c48;"></div> SIREDOM - Secteur orange
            </div>
          </div>
        </v-col>

        <v-col cols="3" md="3" sm="6">
          <div class="ml-13 text-left">
            <div>
              <div class='box mr-1' style="background-color: #eef071;"></div> CAESE - Étampes
            </div>
          </div>

          <div class="ml-13 mt-2 text-left">
            <div>
              <div class='box mr-1' style="background-color: #d61111;"></div> créneau indisponible
            </div>
          </div>
        </v-col>

      </v-row>
    </v-row>
  </v-main>
</template>

<script>
import api from "@/api/creneaux";
// import apiSecteurs from "@/api/secteurs";
// import apiCollectes from "@/api/collectes";

export default {
  components: {
  },

  data() {
    return {

      datas: [],
      previousWeek: 0,
      nextWeek: 0,
      index: 0,
      creneaux: [],

    };
  },

  methods: {

    async getCreneaux(index) {
      let creneaux;
      if (index == undefined) {
        creneaux = await api.getCreneauxAdmin(this.index);
        this.previousWeek = creneaux.data.previousWeek
        this.nextWeek = creneaux.data.nextWeek
      } else {
        creneaux = await api.getCreneauxAdmin(index);
        this.index = index
        this.previousWeek = creneaux.data.previousWeek
        this.nextWeek = creneaux.data.nextWeek
      }
      // alert(this.index)
      creneaux = creneaux.data.creneaux;
      // if ( creneaux.length == 0 ) this.index = this.index - 1

      //  récupérer le numéro de semaine courant

      // date début de la semaine courante
      let startDay = this.$moment().add(this.index, "week");
      startDay = this.$moment(startDay).startOf("week").toDate();
      // date de fin de la semaine courante
      let endDay = this.$moment().add(this.index, "week");
      endDay = this.$moment(endDay).endOf("week").toDate();

      let week = this.$moment(startDay).isoWeek();

      // variable récupérer les dates de la semaine
      let range;
      let array = [];
      // 1 boucle par semaine

      range = this.getRange(startDay, endDay, "days");

      array.push({ week: week, dates: range });
      startDay = this.$moment(startDay, "DD-MM-YYYY").add(7, "days");
      week = this.$moment(startDay).isoWeek();
      endDay = this.$moment(endDay, "DD-MM-YYYY").add(7, "days");

      this.datas = array;
      console.log(this.datas, "datas après range");

      creneaux.forEach((creneau) => {
        this.datas.forEach((week) => {
          week.dates.forEach((date) => {
            if (date.creneau == false) {
              date.creneau = ["AM", "PM"];
            }
            console.log(date, "date in foreach");
            if (this.$moment(date.date).format("LL") == this.$moment(creneau.date).format("LL")) {
              if (creneau.heure_debut < "12:00:00") {
                date.creneau[0] = creneau
              } else {
                date.creneau[1] = creneau
              }
              // date.creneau.push(creneau);
              console.log(week, "week");
            }
          });
        });
      });

      console.log(this.datas, "datas avec creneau planning admin");
    },

    getRange(startDate, endDate, type) {
      let fromDate = this.$moment(startDate);
      let toDate = this.$moment(endDate);
      let diff = toDate.diff(fromDate, type);
      let range = [];
      for (let i = 0; i < diff; i++) {
        range.push({
          date: this.$moment(startDate).add(i, type),
          creneau: false,
        });
      }
      return range;
    },

    getPreviousWeek() {
      this.index = this.index - 1
      this.getCreneaux();
    },

    getNextWeek() {
      this.index = this.index + 1
      this.getCreneaux();
    },

    getCreneauColor(creneau) {
      var classCreneau = ''
      if (creneau.Secteur.Marche.marche == 'CCVE') {
        creneau == 'AM' || creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place'
        return classCreneau
      } else if (creneau.Secteur.Marche.marche == 'CCVE') {
        creneau == 'PM' || creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place'
        return classCreneau
      } else if (creneau.Secteur.Marche.marche == 'SIREDOM') {
        creneau == 'AM' || creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place_siredom'
        return classCreneau
      } else if (creneau.Secteur.Marche.marche == 'SIREDOM') {
        creneau == 'PM' || creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place_siredom'
        return classCreneau
      } else if (creneau.Secteur.Marche.marche == 'CAESE ALLO') {
        creneau == 'AM' || creneau == 'PM' || creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place_etampes'
        return classCreneau
      } else {
        console.log("creneau", creneau);
        creneau.compteur == 0 ? classCreneau = 'noplace' : classCreneau = 'place'
        return classCreneau
      }
    },

    goToDashboard(creneau) {
      this.$store.commit("dateCreneau", creneau.date);
      this.$store.commit("indexPlanning", this.index);
      this.$router.push({ name: 'dashboard' })
    }


  },

  async created() {
    var savedIndex = this.$store.state.indexPlanning
    this.getCreneaux(savedIndex);
  },
};
</script>

<style scoped>
/* @import "export.css"; */
.planning_admin {
  background-color: #fff;
  height: calc(100vh - 100px);
  border-radius: 20px;
}

.title {
  text-align: left;
  color: #93b121;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal bold 1.14285714rem Roboto;
  margin-bottom: 0px;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #93b121;
  align-self: center;
  margin-top: 0.9vh;
  margin-bottom: -2vh;
}

.black_line {
  border-bottom: solid 1px black;
  width: 100%;
  vertical-align: baseline;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.creneau {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.noCreneau {
  background-color: lightgray;
}

.noCreneau {
  background-color: lightgray;
}

.creneau.place_etampes {
  background-color: #eef071;
  cursor: pointer;
  max-height: 7.5rem;
}

.creneau.place {
  background-color: #93b121;
  cursor: pointer;
  max-height: 7.5rem;
}

.creneau.place_siredom {
  background-color: blue;
  cursor: pointer;
  max-height: 7.5rem;
}

.creneau.noplace {
  background-color: #d61111;
  max-height: 7.5rem;
}

.creneau.noplace_siredom {
  background-color: black;
  max-height: 7.5rem;
}

.creneau,
.noCreneau {
  height: 150px;
}

.v-btn {
  min-width: 0 !important;
}

.creneau.place>.v-btn {
  background-color: #93b121;
}

button.btn_voir_plus.v-btn.v-btn--outlined.theme--light.v-size--default {
  border-radius: 10px;
}

.flou {
  opacity: 0.5;
}

.pr-0.col.col-6 {
  height: 45%;
}

.col.col-12.creneau.place,
.col.col-12.creneau.noplace,
.col.col-12.noCreneau {
  /* max-height: 5.5rem; */
  max-height: 7.5rem;
}

.w100 {
  width: 100%;
}

.w100.matin {
  margin-bottom: 1px;
}

.date_calendar {
  color: #af2c70;
  font-family: "Roboto", sans-serif;
  line-height: initial;
  font-size: 2rem;

}

.size {
  width: 80%
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  clear: both;
}
</style>