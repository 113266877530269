<template>
  <div class="accueil">
              <v-row no-gutters>
                  <span
              v-if="$store.state.dateCreneau != '' && $store.state.dateCreneau != $moment().format('YYYY-MM-DD')"
              @click="$router.go(-1)"
              class="boutton_planning mb-0"
              >RETOUR AU PLANNING</span>
      </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 :class="$store.state.dateCreneau != '' ? 'mt-0 ml-5 planning text-left' : 'mt-5 ml-5 planning text-left'" align="start">{{ title }}</h2>
        <div class="line ml-5"></div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <h1
        v-if="planning.length > 0"
        align="start"
        class="text-capitalize font-weight-thin text-h3 ml-5 mt-16 date"
      >
        {{ $store.state.dateCreneau != '' ? $moment($store.state.dateCreneau).format("dddd LL") : $moment().format("dddd LL")  }}
      </h1>
      <h1 align="start" class="font-weight-thin text-h3 ml-5 mt-16 date" v-else>{{message}}</h1>
    </v-row>


    <v-row align="stretch" no-gutters>
      <v-col class="pr-2 mt-8" cols="6" sm="6" md="6" v-for="card in planning" :key="card.id_creneau">
        <collectsCard
          :items="items"
          :btnExport="btnExport"
          :planning="card"
          :camions="camions"
          :field="field"
          @updateCamion="updateCamion"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import collectsCard from "@/components/admin/dashboard/collectsCard";
import apiPlanning from "@/api/collectes";
import apiCamions from "@/api/camions";
import apiCreneau from "@/api/creneaux";

export default {
  name: "Accueil",

  data() {
    return {
      planning: [],
      camions: [],
      items: [],
      date: false,
      message: '',

      btnExport: [
        {
          name: "Export de la tournée",
          urlIcon: "#",
          method: "exportTournee",
        },
      ],

      field: {
        label: "Valider",
        rules: "required",
        value: "",
        type: "select",
      },
    };
  },

  components: {
    collectsCard,
  },

  props: {
    title: {
      type: String,
      default: () => "",
    },
  },

  methods: {
    getPlanning() {
          console.log(this.$store.state.dateCreneau, "date get planning")
      apiPlanning.getPlanning(this.date).then((res) => {
        this.planning = res.data.message;
        if ( this.planning.length == 0) this.message = "Pas de créneaux prévus aujourd'hui."
        console.log("get planning", res.data);
      });
    },

    getCamions() {
      apiCamions.getCamions().then((res) => {
        this.camions = res.data.camions;

        this.camions.forEach((camion) => {
          camion["label"] = camion.titre + " - " + camion.volume + "m3";
          this.items.push(camion);
        });

        console.log("Les camions: ", this.camions);
        console.log("Les items: ", this.items);
      });
    },

    updateCamion(obj) {
      apiCreneau.updateCamion(obj.creneau, obj.camion).then(() => {
        this.getPlanning();
      });
    },
  },

  watch: {
    '$store.state.dateCreneau'(nv, ov) {
      this.date = this.$store.state.dateCreneau == "" ? this.$moment().format("YYYY-MM-DD") : this.$store.state.dateCreneau
      this.getPlanning();
      this.getCamions();
      console.log(nv, ov, "watch date");
    },
  },
  mounted() {
    console.log("mounted")
    this.date = this.$store.state.dateCreneau == "" ? this.$moment().format("YYYY-MM-DD") : this.$store.state.dateCreneau
    this.getPlanning();
    this.getCamions();
    // this.$root.$on("refreshPlanning" , () => {
    //   console.log("date event", this.date)
    //         this.getCamions();
    //   return this.getPlanning(this.date);

    // })
  },
};
</script>

<style scoped>
.accueil {
  background-color: #fff;
  border-radius: 14px;
  /* min-height: 100vh; */
}
h2 {
  font-size: 16px;
  font-weight: bold;
  color: #93b121;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #93b121;
  align-self: center;
  margin-top: 0.9vh;
  margin-bottom: -2vh;
}
.accueil .planning {
  /* color: #93b121; */
}

.accueil div {
  padding: 3px 0;
  /* background-color: #93b121; */
}

.accueil .date {
  color: #af2c70;
}

.accueil div[data-v-11963bce] {
  /* background-color: #fff; */
}

.boutton_planning {
  position: relative;
  bottom: 30px;
  left: 5px;
  color: #ea7e2a;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>
