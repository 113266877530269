<template>
  <v-main>
    <v-row>
      <v-col v-for="item in secteurs" :key="item.id" cols="3">
        <v-card 
          class="border-secteur pt-3 px-0 pt-0"
          :elevation="0"
          v-bind:style=" { backgroundColor: item.color} "
>
          <v-row>
            <v-col class="pb-0" cols="4"></v-col>
            <v-col class="px-0 titre_rose pb-0" cols="4">{{
              item.nom_secteur
            }}</v-col>
            <v-col class="pb-0" cols="4"
              ><img
                class="fit_img"
                v-bind:src="url + item.TypeClient.url_logo"
                alt=""
            /></v-col>
            <v-col class="statut pb-0" cols="12">{{
              item.TypeClient.typologie
            }}</v-col>
            <v-col cols="10" class="line_secteur pb-0"></v-col>
          </v-row>

          <v-row>
            <v-col class="lien pt-0" cols="12"
              ><a href="#" style="text-decoration: underline;" @click="getCreneaux(item.id_secteur, item)"
                >voir les créneaux</a
              ></v-col
            >
            <v-col class="lien pt-0" cols="12"
              ><a style="text-decoration: underline;" @click="elementToPopup(item)" href="#"
                >voir les communes</a
              ></v-col
            >
            <v-col cols="10" class="line_secteur pb-0"></v-col>
          </v-row>
          <v-row v-if="item.Camion" class="">
            <v-col class="lien pa-0" cols="12"
              ><img
                class="fit_img_flotte"
                src="@/assets/images/administration/flotte-rose.png"
                alt=""
            /></v-col>
            <v-col class="lien pa-0" cols="12">{{ item.Camion.titre }}</v-col>
            <v-col class="lien pa-0" cols="12">{{
              item.Camion.reference
            }}</v-col>
            <v-col class="lien pa-0 pb-10" cols="12">{{
              item.Camion.modele
            }}</v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <PopupMarche
      :title="title"
      :idSecteur="idSecteur"
      :btn="btn"
      :page="page"
      :nbpage="nbpage"
      :show="show2"
      :query="query"
      @close="show2 = false"
      @show="show2 = true"
      @querySearch="querySearch($event)"
      @changePage="changePage($event)"
      @deleteCommune="deleteCommune($event)"
      @reloadSearch="reloadSearchFunction"
    />
    <v-row class="mb-4 mt-4">
      <v-btn
        @click="showSecteurPop = true"
        class="btn_add ml-3"
        :elevation="0"
        rounded
        >Ajouter un secteur</v-btn
      >
      <v-btn
        @click="showTarifPop = true"
        class="btn_add ml-3"
        :elevation="0"
        rounded
        >Ajouter un tarif</v-btn
      >

      <v-btn
        @click="showViewTarifPop = true, getTarifs(id_marche)"
        class="btn_add ml-3"
        :elevation="0"
        rounded
        >Voir tarifs</v-btn
      >
    </v-row>
    <v-row>
      <SecteurPop
        :fields="fields"
        :options="options"
        :btn2="btn2"
        :titleSecteur="titleSecteur"
        :showSecteurPop="showSecteurPop"
        :camions="camions"
        @close="showSecteurPop = false"
        @createSecteur="createSecteur"
      />

      <SecteurPop
        :fields="fieldsTarif"
        :options="options"
        :btn2="btn3"
        :titleSecteur="titleTarif"
        :showSecteurPop="showTarifPop"
        :camions="camions"
        @close="showTarifPop = false"
        @addTarif="addTarif"
      />

      <SecteurPop
        :titleSecteur="titleViewTarif"
        :tarifs="tarifs"
        :showSecteurPop="showViewTarifPop"
        @close="showViewTarifPop = false"
      />
    </v-row>

    <v-row>
      <PopupCreneaux
        :idSecteur="idSecteur"
        :title="title"
        :show="show"
        :creneaux="creneaux"
        :btnCreneaux="btnCreneaux"
        :secteur="secteur"
        @close="
          show = false;
          title = '';
        "
        @getCreneaux="getCreneaux"
      />
    </v-row>

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error.id"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error.msg }}
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
import PopupCreneaux from "./popupCreneaux.vue";
import PopupMarche from "../marche/popupMarche.vue";
import SecteurPop from "@/components/popups/secteurPop";
import apiSecteur from "@/api/secteurs";
import apiType from "@/api/types";
import apiMarches from "@/api/marches";
import apiCamions from "@/api/camions";

export default {
  name: "ccve",

  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      show: false,
      show2: false,
      showSecteurPop: false,
      showTarifPop: false,
      title: "",
      btn: "ajouter une commune",
      communes: [],
      query: "",
      idSecteur: 0,
      page: 0,
      totalItems: 0,
      nbpage: 0,
      allCommunes: [],
      mode: "secteur",
      creneaux: [],
      secteur:{},

      // Popup créneaux
      btnCreneaux: [
        { name: "Ajouter un créneau", method: "addCreneaux" },
        { name: "supprimer", method: "deleteCreneau" },
      ],

      // Popup création secteur
      fields: [
        {
          name: "nom_secteur",
          placeholder: "Nom du secteur",
          rules: "required",
          value: "",
          type: "text",
          label: "nom du secteur",
        },
        {
          name: "id_type_client",
          placeholder: "Type (bailleur / particulier)",
          rules: "required",
          value: "",
          type: "select",
          label: "type de secteur",
        },
        {
          name: "id_camion",
          placeholder: "Camion",
          rules: "required",
          value: "",
          type: "select",
          label: "camion",
        },
      ],
      options: [],
      camions: [],
      tarifs: [],
      btn2: [
        { name: "Créer le secteur", method: "createSecteur" },
        { name: "Fermer", method: "close" },
      ],
      titleSecteur: "Ajouter un secteur",

      // Popup ajout tarif
      fieldsTarif: [
        {
          name: "start_tarif",
          placeholder: "Date de début du tarif",
          rules: "required",
          value: "",
          type: "date_tarif",
          label: "Date de début du tarif",
        },
        {
          name: "id_type_client",
          placeholder: "Type (bailleur / particulier)",
          rules: "required",
          value: "",
          type: "select",
          label: "type de secteur",
        },
        {
          name: "tarif_administre",
          placeholder: "Tarif administré",
          rules: "",
          value: "",
          type: "number_tarif",
          label: "Tarif administré",
        },
        {
          name: "tarif_cancel",
          placeholder: "Tarif annulé",
          rules: "",
          value: "",
          type: "number_tarif",
          label: "Tarif annulé",
        },
        {
          name: "tarif_syndicat",
          placeholder: "Tarif syndicat",
          rules: "",
          value: "",
          type: "number_tarif",
          label: "Tarif syndicat",
        },                
      ],
      btn3: [
        { name: "Ajouter le tarif", method: "addTarif" },
        { name: "Fermer", method: "close" },
      ],
      titleTarif: "Ajouter un tarif",
      //popup voir tarif
      showViewTarifPop: false,
      titleViewTarif: "Tarifs en vigueur",


      snackbar: false,
      timeout: 3000,
      errors: [],
      color: "red darken-2",
    };
  },

  components: { PopupMarche, SecteurPop, PopupCreneaux },

  props: {
    id_marche: {
      type: Number,
      default: () => 0,
    },
    secteurs: {
      type: Array,
      default: () => [],
    },
    marche: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    createSecteur(error) {
      if (error.valid) {
        this.color = "#93b122";
        this.errors = [{msg :"Le secteur " + this.fields[0].value + " a été crée",id:Math.random() * 100}];
        this.snackbar = true;
        let data = {};
        this.fields.forEach((field) => {
          data[field.name] = field.value;
        });
        data["id_marche"] = this.id_marche;
        apiSecteur.createSecteur(data).then(() => {
          this.getSecteurs(this.id_marche);
          this.fields.forEach((field) => {
            field.value = "";
          });
          this.showSecteurPop = false;
        });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }

      // let data = {};
      // this.fields.forEach((field) => {
      //   data[field.name] = field.value;
      // });
      // data["id_marche"] = this.id_marche;
      // apiSecteur
      //   .createSecteur(data)
      //   .then((res) => {
      //     console.log(res.data);
      //     this.getSecteurs(this.id_marche);
      //     this.fields.forEach((field) => {
      //       field.value = "";
      //     });
      //   })
      //   .catch((error) => {
      //     if (
      //       401 === error.response.status ||
      //       400 === error.response.status ||
      //       500 === error.response.status
      //     ) {
      //       let msg = "VEUILLEZ REMPLIR TOUS LES CHAMPS";
      //       this.snackbar = true;
      //       this.errors.push(msg);
      //     }
      //   });
    },

    addTarif(error) {
      if (error.valid) {
        this.color = "#93b122";
        this.errors = [{msg :"Le tarif " + this.fields[0].value + " a été crée",id:Math.random() * 100}];
        this.snackbar = true;
        let data = {};
        this.fieldsTarif.forEach((field) => {
          data[field.name] = field.name !== "id_type_client" ? field.value.replace(/,/g, '.') : field.value;
        });
        data["id_marche"] = this.id_marche;
        apiMarches.addTarif(data).then((res) => {
          console.log("🚀 ~ file: ccve.vue:366 ~ res:", res)
          this.fieldsTarif.forEach((field) => {
            field.value = "";
          });
          this.showTarifPop = false;
        },(error) => {
          console.log("🚀 ~ file: ccve.vue:354 ~ error:", error)
        });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }    },

    getCamions() {
      apiCamions.getCamions().then((res) => {
        this.camions = res.data.camions;
      });
    },

    getTarifs(id_marche) {
      apiMarches.getTarifs(id_marche).then((res) => {
        this.tarifs = res.data.tarifs;
      });
    },

    getCreneaux(id_secteur, e) {
      apiSecteur.getFrequence(id_secteur).then((res) => {
        this.creneaux = res.data.frequences;
        let arraytab = [];
        let today = this.$moment().format('DD/MM/YYYY');
        console.log(today,'today')
        this.creneaux.forEach(creneau => {
          console.log(creneau.date_fin,'foreauch date fin',new Date(today)< new Date(creneau.date_fin),this.$moment().format('DD/MM/YYYY') > this.$moment(creneau.date_fin))
          // if(this.$moment(creneau.date_fin) > this.$moment()){
          //   arraytab.push(creneau);
          // }
        })
        console.log(arraytab,'array tab');
        // this.creneaux = this.creneaux.filter(creneau => this.$moment(creneau.date_fin) > this.$moment() )
        this.idSecteur = id_secteur;
        this.secteur = e
        console.log(this.secteur,"secteur ccve")
        this.show = true;
        this.title =
          "CRÉNEAUX " + this.title + e.nom_secteur + " - " + this.marche.marche;
        console.log("frequence ", this.creneaux);

        let days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];
        let daysCreneaux = [];

        this.creneaux.forEach((creneau) => {
          daysCreneaux.push(creneau.jour);
          creneau["idjour"] = this.findidjour(creneau.jour);
          console.log(creneau,'ccccccccccccccccccc')
        });

        days.forEach((day) => {
          console.log(day, daysCreneaux.includes(day));
          if (!daysCreneaux.includes(day)) {
            this.creneaux.push({
              date_debut: "",
              date_fin: "",
              frequence: "-",
              horaires: "-",
              id_frequence: 0,
              jour: day,
              idjour: this.findidjour(day),
              id_creneau:0
            });
          }
        });

        // Sort creneaux

        this.creneaux.sort(function (a, b) {
          return a.idjour - b.idjour;
        });

        console.log(this.creneaux, "result");
      });
    },

    findidjour(jour) {
      let id;

      switch (jour) {
        case "lundi":
          id = 0;
          break;
        case "mardi":
          id = 1;
          break;
        case "mercredi":
          id = 2;
          break;
        case "jeudi":
          id = 3;
          break;
        case "vendredi":
          id = 4;
          break;
      }

      return id;
    },

    deleteCreneaux() {
      console.log("deletion creneau ?");
    },

    getTypes() {
      apiType.getAllTypes().then((res) => {
        this.options = res.data.types;
      });
    },
    reloadSearchFunction() {
      return this.getAllCommunesBySecteur(
        this.idSecteur,
        (this.query = ""),
        (this.page = 0)
      );
    },
    popupMarche() {
      this.show2 = true;
    },
    elementToPopup(e) {
      this.title = "";
      this.show2 = true;
      console.log(this.marche, "marccccccccccccccccccccccccc");
      this.title =
        "COMMUNES " + this.title + e.nom_secteur + " - " + this.marche.marche;
      this.idSecteur = e.id_secteur;
      console.log(this.idSecteur, "iddd");
      this.getAllCommunesBySecteur(e.id_secteur, this.query, this.page);
    },
    changePage(obj) {
      console.log(obj, "obj changePage");
      this.page = obj.page;
      console.log(this.idSecteur, "secteur change page");
      this.getAllCommunesBySecteur(this.idSecteur, obj.query, this.page);
    },
    getAllCommunesBySecteur(id_secteur, query, page) {
      apiSecteur
        .getCommunes(id_secteur, query, page)
        .then((res) => {
          this.query = query;
          this.communes = res.data.communes.rows;
          this.page = res.data.communes.currentPage + 1;
          this.totalItems = res.data.communes.totalItems;
          this.nbpage = res.data.communes.totalPages;
          console.log(this.page, "page getallcommune");
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    // getAllCommunes(query, page) {
    //   apiSecteur
    //     .getAllCommunes(query, page)
    //     .then((res) => {
    //       this.query = query;
    //       this.allCommunes = res.data.communes.rows;
    //       this.page = res.data.communes.currentPage + 1;
    //       this.totalItems = res.data.communes.totalItems;
    //       this.nbpage = res.data.communes.totalPages;
    //       console.log("alexxx",this.page,res.data.communes.rows ,"page getallcommune");
    //     })
    //     .catch((err) => {
    //       console.log("error", err);
    //     });
    // },
    getSecteurs(id_marche) {
      apiMarches.getAllSecteurs(id_marche).then((res) => {
        console.log("test reload secteurs");
        this.secteurs = res.data.secteurs;
        console.log(this.secteurs);
      });
    },
    querySearch(obj) {
      this.query = obj.query;
      console.log(this.page);

      this.getAllCommunesBySecteur(this.idSecteur, obj.query, obj.page);
      // this.getAllCommunes(obj.query, obj.page)
    },
    deleteCommune(data) {
      apiSecteur.deleteCommune(data.id_secteur, data.id_commune).then(() => {
        this.getAllCommunesBySecteur(data.id_secteur, this.query, this.page);
      });
    },
  },

  created() {
    this.getTypes();
    this.getCamions();
    console.log("les sec ", this.secteurs);

        this.$root.$on("refreshTitle", () => {
          this.title = ''
          this.show = false

    });
  },
};
</script>

<style scoped>
button.btn_add.v-btn.v-btn--has-bg.v-btn--rounded.theme--light.v-size--default {
  background-color: #cc0000;
  color: white;
  font-weight: bold;
}
/* ::v-deep .btn_add{
    background-color: red;
    color: white;
} */
.fit_img_flotte {
  margin-top: -31px;
  margin-bottom: -31px;
}
.line_secteur {
  border-top: 1px solid white;
  margin-left: 19px;
}
.statut {
  margin-top: -17px;
  font-weight: bold;
}
.fit_img {
  position: absolute;
  max-width: 7%;
  margin-left: -6px;
  margin-top: 6px;
}

.titre_rose {
  color: #af2c70;
  font-weight: bold;
}
::v-deep .border-secteur {
  border: 1px solid #f2f2f2;
  border-radius: 10px !important;
  background-color: #f2f2f2;
}

.lien a {
  color: #af2c70;
}

#snack {
  position: fixed;
  top: 0;
  right: 0;
}
</style>
