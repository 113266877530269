<template>
  <v-main full-width class="export-page">
    <v-row>
      <v-col cols="12">
        <h1
          style="color: #93b121"
          class="font-weight-bold text-h5 text-left ml-5 text-uppercase"
        >
          Export
        </h1>
        <div class="line ml-5"></div>
      </v-col>
    </v-row>

    <ValidationObserver ref="form">
      <form class="ml-16 export-form">
        <div id="form" v-for="field in fields" :key="field.label">
          <v-row v-if="field.type == 'marches'" no-gutters>
            <v-col cols="12">
              <ValidationProvider :name="field.name" :rules="field.rules">
                <v-select
                  class="rounded-lg font-italic"
                  outlined
                  :placeholder="field.placeholder"
                  :label="field.label"
                  v-model="field.value"
                  :items="marches"
                  item-text="marche"
                  item-value="id_marche"
                  no-data-text="Pas de marché"
                  hide-details
                  @change="fields[1].value = [], $emit('getPlageHoraire', []),  $emit('getSecteurs', field.value.id_marche)"
                  return-object
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="field.type == 'secteurs'" no-gutters>
            <v-col cols="12">
              <ValidationProvider :name="field.name" :rules="field.rules">
                <v-select
                  class="rounded-lg font-italic"
                  outlined
                  :placeholder="field.placeholder"
                  :label="field.label"
                  v-model="field.value"
                  :items="secteurs"
                  item-text="nom_secteur"
                  item-value="id_secteur"
                  no-data-text="Sélectionnez un marché"
                  multiple
                  hide-details
                  @change="$emit('getPlageHoraire', field.value)"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="field.type == 'statut'" no-gutters>
            <v-col cols="12">
              <ValidationProvider :name="field.placeholder" :rules="rules.statutCollecte">
                <v-select
                  class="rounded-lg font-italic"
                  outlined
                  :placeholder="field.placeholder"
                  :label="field.label"
                  v-model="field.value"
                  :items="types"
                  item-text="type"
                  item-value="type"
                  no-data-text="Sélectionnez le(s) statut(s)"
                  multiple
                  hide-details
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- <v-row v-if="field.type == 'date'" no-gutters>
            <v-col cols="12">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="rounded-lg font-italic"
                      outlined
                      v-model="field.value"
                      :label="field.placeholder"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="field.value"
                    no-title
                    show-adjacent-months
                    range
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </ValidationProvider>
            </v-col>
          </v-row> -->

          <v-row
            v-if="field.type == 'date'"
            no-gutters
            class="h"
            align="center"
            style="height: 100%"
          >
            <v-col cols="12">

                <v-menu
                  ref="dates"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="field.value"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                                  <ValidationProvider :name="field.type" :rules="field.rules">
                    <v-text-field
                      :value="field.value"
                      outlined
                      v-model="dateRangeText"
                      :placeholder="field.placeholder"
                      persistent-hint
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      hide-details
                      class="rounded-lg font-italic"
                    >
                   
                                          <!-- <template v-slot:[`field.value`]="{ field }">
                        {{field[0] - "blabla"}}
                      </template> -->
                      <template v-slot:append>
                        <img src="@/assets/images/administration/calendrier.png" alt="Calendar">
                      </template>
                    </v-text-field>
                                  </ValidationProvider>
                  </template>
                  <v-date-picker
                    range
                    v-model="field.value"
                    no-title
                    locale="fr"
                    show-adjacent-months
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="field.value = []"
                      >Annuler</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.dates[0].save(field.value)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>

            </v-col>
          </v-row>

          <v-row class="" v-if="field.type == 'timestamp'" no-gutters>
            <v-col cols="12">
              <ValidationProvider :name="field.placeholder" :rules="rules.plageHoraires">
                <v-select
                  class="rounded-lg font-italic input-form"
                  outlined
                  :placeholder="field.placeholder"
                  :label="field.label"
                  v-model="field.value"
                  :items="horaires"
                  no-data-text="Pas de creneau de disponible"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>

        <v-row no-gutters>
          <v-col cols="4" v-if="$store.state.user.Role.role == 'administrateur'">
            <div 
              :class="{ blueDiv: isActive }"
              class="export-btn rounded-lg pt-6"
              @click=" exportTournee()"
            >
              <!-- <img
                v-if="isActive"
                class="export-img"
                src="@/assets/images/administration/flotte-blanc.png"
                alt="Flotte rose"
              /> -->
              <img
                class=""
                src="@/assets/images/administration/flotte-rose-export.png"
                alt="Flotte rose"
              />
              <h3 :class="{ blueDiv: isActive }" class="export-txt mb-8">
                Export de la tournée
              </h3>
            </div>
          </v-col>
          <v-col cols="4" >
            <div @click="exportFacturationDetaillee()"
               class="export-btn rounded-lg pt-6">
              <img src="@/assets/images/administration/facturation.png" alt="Facturation">
              <h3  class="export-txt mb-8">Facturation client</h3>
            </div>
          </v-col>
          <v-col cols="4" v-if="$store.state.user.Role.role == 'administrateur'">
            <div
              @click=" exportFacturationMensuelle()"
              class="export-btn rounded-lg pt-6"
            >
              <img src="@/assets/images/administration/facturation.png" alt="Facturation">
              <h3 class="export-txt mb-8">Facturation Mensuelle</h3>
            </div>
          </v-col>
        </v-row>

            <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>

        <!-- <v-row align="center" no-gutters>
          <template v-if="btn.length == 1">
            <v-col
              align="center"
              cols="12"
              xl="12"
              lg="12"
              v-for="button in btn"
              :key="button.label"
            >
              <v-btn
                width="40%"
                class="font-weight-bold text-center rounded-lg mt-10"
                color="#AF2C70"
                large
                dark
                depressed
                v-if="button.type == 'btn'"
              >
                {{ button.label }}
              </v-btn>
            </v-col>
          </template>
        </v-row> -->
      </form>
    </ValidationObserver>
  </v-main>
</template>

<script>
import api from "@/api/marches";

export default {
  name: "Export",

  data() {
    return {
      rules: {
        plageHoraires: 'required',
        statutCollecte: 'required'
      },
      menu: false,
      isActive: false,
      isActive2: false,
      isActive3: false,
      snackbar: false,
    };
  },

  computed: {
      dateRangeText () {
        let dates = [...this.fields[3].value]
        for ( var i=0; i < dates.length; i++) {
          dates[i]= this.$moment(dates[i]).format("DD/MM/YYYY")
        }
        return dates.join(' - ')
      },
    // computedDateFormattedMomentjs : {
    //   cache:false,
    //   get(){
    //   let date = this.fields[3].value
    //   for ( var i=0; i < date.length; i++) {
    //     // date[i] = this.$moment(date[i]).format("DD/MM/YYYY")
    //   }
    //   date = date[0] + ' - ' + date[1]
    //   return date;
    //   }

    // },
  },

  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    btn: {
      type: Array,
      default: () => [],
    },

    marches: {
      type: Array,
      default: () => [],
    },

    secteurs: {
      type: Array,
      default: () => [],
    },

    types: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    horaires: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async exportTournee() {
      let mode = "tournee"
      var changed = await this.changeRules(mode)
      console.log(changed)
      let errors =[];
      this.$refs.form.validate().then((valid) => {
        if ( valid == true ) {

          var data = {
            id_marche: this.fields[0].value.id_marche,
            id_secteur: this.fields[1].value,
            type: this.fields[2].value,
            date_debut: this.$moment(this.fields[3].value[0]).format('YYYY-MM-DD'),
            date_fin: this.$moment(this.fields[3].value[0]).format('YYYY-MM-DD'),
            heure_debut: this.$moment(this.fields[4].value.slice(0, 5).replace("h", ":"), 'HH:mm').format('HH:mm:ss'),
            heure_fin: this.$moment(this.fields[4].value.slice(-5).replace("h", ":"), 'HH:mm').format('HH:mm:ss'),
          }

          console.log(data, "data tournee")
            this.color = "#93b122";
            this.errors = ['Export en cours veuillez patientez jusqu\'au téléchargement']
            this.snackbar = true
            this.timeout = 999999

          api.exportTournee(data).then((res) => {
          if ( res.data.type == 'text/plain') {
            this.color = "red darken-2";
            this.errors = ['pas de données disponibles']
            this.snackbar = true
          } else {
                      this.snackbar = false
           var filename = `tournee_du_${this.$moment(data.date_debut).format("DD-MM-YYYY")}_${this.fields[0].value.marche}.xlsx`
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
          }
          console.log(res)

        })
        .catch((err) => {
          console.log("error", err);
        });

        } else {
          //display error
          console.log(valid);
          console.log(this.$refs.form);
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              errors.push(msg);
            });
          }
          console.log(errors);
          this.color = "red darken-2";
          this.errors = errors
          this.snackbar = true
          // this.$emit(method, { valid: valid, errors: errors, e: e });
        }

      });
    },

    async exportFacturationDetaillee() {
      let mode = "detaillee"
      var changed = await this.changeRules(mode)
      console.log(changed)
      let errors =[];
      // console.log("validation facture detaillée", this.fields[4].rules)
      this.$refs.form.validate().then((valid) => {
        if ( valid == true ) {

          var data = {
            id_marche: this.fields[0].value.id_marche,
            id_secteur: this.fields[1].value,
            type: this.fields[2].value,
            date_debut: this.$moment(this.fields[3].value[0]).format('YYYY-MM-DD'),
            date_fin: this.$moment(this.fields[3].value[1]).format('YYYY-MM-DD'),
            // heure_debut: this.$moment(this.fields[4].value.slice(0, 5).replace("h", ":"), 'HH:mm').format('HH:mm:ss'),
            // heure_fin: this.$moment(this.fields[4].value.slice(-5).replace("h", ":"), 'HH:mm').format('HH:mm:ss'),
          }

          console.log(this.fields[3].value, "data tournee")

            this.color = "#93b122";
            this.errors = ['Export en cours veuillez patientez jusqu\'au téléchargement']
            this.snackbar = true
            this.timeout = 999999

          api.exportFacturationDetaillee(data).then((res) => {
            this.snackbar = false
          if ( res.data.type == 'text/plain') {
            this.color = "red darken-2";
            this.errors = ['pas de données disponibles']
            this.snackbar = true
          } else {
          var filename = `facturation_detaillee_${this.$moment(data.date_debut).format("DD-MM-YYYY")}_${this.fields[0].value.marche}.xlsx`
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
          }
          console.log(res)

        })
        .catch((err) => {
          console.log("error", err);
        });

        } else {
          //display error
          console.log(valid);
          console.log(this.$refs.form);
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              errors.push(msg);
            });
          }
          console.log(errors);
          this.color = "red darken-2";
          this.errors = errors
          this.snackbar = true
          // this.$emit(method, { valid: valid, errors: errors, e: e });
        }

      });
      // this.fields[4].rules = "required"
      // console.log("validation facture detaillée end", this.fields[4].rules)
    },
    async exportFacturationMensuelle() {
      let mode = "mensuelle"
      var changed = await this.changeRules(mode)
      console.log(changed)
      let errors =[];
      this.$refs.form.validate().then((valid) => {
        if ( valid == true ) {

          var data = {
            id_marche: this.fields[0].value.id_marche,
            id_secteur: this.fields[1].value,
            date_debut: this.$moment(this.fields[3].value[0]).format('YYYY-MM-DD'),
            date_fin: this.$moment(this.fields[3].value[1]).format('YYYY-MM-DD'),

          }

          console.log(data, "data tournee")
            this.color = "#93b122";
            this.errors = ['Export en cours veuillez patientez jusqu\'au téléchargement']
            this.snackbar = true
            this.timeout = 999999

          api.exportFacturationMensuelle(data).then((res) => {
          console.log(res)
          this.snackbar = false
          var filename = `facturation_mensuelle_${this.$moment(data.date_debut).format("DD-MM-YYYY")}_${this.fields[0].value.marche}.pdf`
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                link.setAttribute('target', '_blank')
                document.body.appendChild(link);
                link.click();
                // window.open(url, '_blank')
        })
        .catch((err) => {
          console.log("error", err);
            this.color = "red darken-2";
            this.errors = ['pas de données disponibles']
            this.snackbar = true
        });

        } else {
          //display error
          console.log(valid);
          console.log(this.$refs.form);
          let e = this.$refs.form.errors;
          for (const field in e) {
            e[field].forEach((msg) => {
              errors.push(msg);
            });
          }
          console.log(errors);
          this.color = "red darken-2";
          this.errors = errors
          this.snackbar = true
          // this.$emit(method, { valid: valid, errors: errors, e: e });
        }

      });
      // this.rules.statutCollecte = "required"
    },

    async changeRules(mode) {
      if ( mode == 'mensuelle') {
        this.rules.statutCollecte = ""
        this.rules.plageHoraires = ""
      }

      if ( mode == 'detaillee') {
        this.rules.statutCollecte = "required"
        this.rules.plageHoraires = ""
      }

      if ( mode == 'tournee') {
        this.rules.statutCollecte = "required"
        this.rules.plageHoraires = "required"
      }

    },
  },
};
</script>

<style scoped>
.export-page ::v-deep .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
    height: auto;
    min-height: 56px;
}

#form {
  margin-bottom: 20px;
}

.export-page ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
  margin: auto
}

.export-page {
  background-color: #fff;
  height: calc(100vh - 100px);
  border-radius: 20px;
}

.export-form {
  width: 70%;
  margin-top: 100px;
}

.export-btn {
  cursor: pointer;
  width: 95%;
  border: 1px solid #e3e3e3;
}

.export-img {
  width: 100px;
  height: 100px;
}

.export-txt {
  text-transform: uppercase;
  color: #333333;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #93b121;
  align-self: center;
  margin-top: 0.9vh;
  margin-bottom: -2vh;
}

.blueDiv {
  background-color: #af2c70;
  color: #fff;
}

::v-deep .mdi-menu-down::before {
  content: "\F35D";
  color: #af2c70;
  font-size: 70px;
}

::v-deep .v-text-field input::placeholder {
      font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

@media screen and (max-width: 1500px) {
  .export-form {
    width: 60%;
    margin-top: 60px;
  }

  .export-txt {
    font-size: 12px;
  }
}
</style>