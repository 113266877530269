<template>
  <div>
    <v-overlay :value="showSecteurPop">
 <v-card class="rounded-lg "  :class="frequence.length > 0 ? 'heightCreateCreneau' : '' " light>
        <img id="close" src="@/assets/close.png" @click="$emit('close')" />
        <h3 class="py-5 text-uppercase">{{ titleSecteur }}</h3>

        <!-- {{tarifs}} -->

        <div class="pb-5" v-for="(tarif) in tarifs" :key="tarif.id_prix">
           <h4 class="mt-2 mb-2"> {{tarif.id_type_client == 1 ? "particulier" : "bailleur"}}</h4>
           <div>tarif administré: {{tarif.tarif_administre}} €</div>
           <div>tarif annulé: {{tarif.tarif_cancel}} €</div>
           <div>tarif syndicat: {{tarif.tarif_syndicat}} €</div>
        </div>

        <div v-if="tarifs.length === 0 && fields.length === 0" class="pb-5">
           <h4 class="mt-2 mb-2">Aucun tarifs pour ce marché</h4> 
        </div>

        <div class="mt-10">
          <ValidationObserver ref="form">
            <form>
              <div id="form" v-for="(field, i) in fields" :key="field.label">
                <v-row v-if="field.type == 'text'" no-gutters class="my-5">
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-text-field
                        v-model="field.value"
                        outlined
                        :readonly="field.readonly ? field.readonly : false"
                        :placeholder="field.placeholder"
                        hide-details
                        class="fields"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'email'" no-gutters class="my-5">
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-text-field
                        v-model="field.value"
                        outlined
                        :readonly="field.readonly ? field.readonly : false"
                        :placeholder="field.placeholder"
                        hide-details
                        class="fields"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'number'" no-gutters class="ml-4 my-5">
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"

                    >
                      <v-text-field
                        v-model.number="field.value"
                        outlined
                        :readonly="field.readonly ? field.readonly : false"
                        :placeholder="field.placeholder"
                        hide-details
                        class="fields"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'number_tarif'" no-gutters class="my-5">
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="{
                        required: true,
                        regex: /^\d{1,10}([.,]\d{1,4})?$/,
                      }"
                    >
                      <v-text-field
                        v-model="field.value"
                        outlined
                        :readonly="field.readonly ? field.readonly : false"
                        :placeholder="field.placeholder"
                        hide-details
                        class="fields"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'date_tarif'" no-gutters class="my-5">
                  <v-col cols="6" offset="3">

                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="field.value"
                        offset-y
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                                              <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                          <v-text-field
                            v-model="dateComputed"
                            :placeholder="field.placeholder"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            hide-details
                          ></v-text-field>
                                              </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          locale="fr-fr"
                          :max="$moment().toISOString()"
                          @change="
                            $refs.menu[i].save(date);
                            menu = false;
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'date'" no-gutters class="ml-5 my-5">
                  <v-col cols="2" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu[fields[i]]"
                        :close-on-content-click="false"
                        :return-value.sync="field.value"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="field.value"
                            v-model="field.value"
                            :placeholder="field.placeholder"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="field.value"
                          no-title
                          locale="fr-fr"
                          @change="
                            $refs.menu[i - 1].save(field.value);
                            menu[fields[i]] = false;
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'time'" no-gutters class="my-5">
                  <v-col
                    v-if="field.name == 'heure debut'"
                    cols="2"
                    offset="3"
                    id=""
                  >
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="field.value"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="field.value"
                            :placeholder="field.placeholder"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="field.value"
                          full-width
                          @click:minute="
                            $refs.menu2[0].save(field.value);
                            menu2 = false;
                          "
                        ></v-time-picker>
                      </v-menu>
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    v-if="field.name == 'heure fin'"
                    cols="2"
                    offset="3"
                    id=""
                  >
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="field.value"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="field.value"
                            :placeholder="field.placeholder"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="field.value"
                          full-width
                          @click:minute="
                            $refs.menu3[0].save(field.value);
                            menu3 = false;
                          "
                        ></v-time-picker>
                      </v-menu>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'radio'" no-gutters>
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-radio-group v-model="field.value" row>
                        <v-radio
                          v-for="option in field.options"
                          :key="option.value"
                          :label="option.value"
                          :value="option.value"
                        ></v-radio>
                      </v-radio-group>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="field.name == 'id_role'" class="my-5">
                  <v-col cols="6" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-select
                        outlined
                        :items="roles"
                        item-text="role"
                        item-value="id_role"
                        v-model="field.value"
                        :placeholder="field.placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row no-gutter v-if="field.name == 'jour'" class="ml-0 my-5">
                  <v-col cols="6" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-select
                        outlined
                        :items="days"
                        item-value="jour"
                        item-text="name"
                        v-model="field.value"
                        :placeholder="field.placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row
                  no-gutter
                  v-if="field.name == 'id_occurence'"
                  class="ml-0 my-5"
                >
                  <v-col cols="6" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-select
                        outlined
                        :items="frequence"
                        item-value="id_occurence"
                        item-text="occurence"
                        v-model="field.value"
                        :placeholder="field.placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row
                  no-gutters
                  v-if="field.name == 'id_type_client'"
                  class="my-5"
                >
                  <v-col cols="6" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-select
                        outlined
                        :items="options"
                        item-text="typologie"
                        item-value="id_type_client"
                        v-model="field.value"
                        :placeholder="field.placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="field.name == 'id_camion'" class="my-5">
                  <v-col cols="6" offset="3">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-select
                        outlined
                        :items="camions"
                        item-text="titre"
                        item-value="id_camion"
                        v-model="field.value"
                        :placeholder="field.placeholder"
                        hide-details
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row v-if="field.type == 'file'" no-gutters class="my-5">
                  <v-col cols="6" offset="3" id="">
                    <ValidationProvider
                      :name="field.label"
                      :rules="field.rules"
                    >
                      <v-file-input
                        :placeholder="field.placeholder"
                        append-icon="mdi-folder-open"
                        accept="image/png, image/jpg"
                        dense
                        outlined
                        v-model="field.value"
                        prepend-icon=""
                      ></v-file-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
            </form>

            <!-- <v-row align="center" justify="center" no-gutter>
            <v-col v-if="field.type == 'text'" cols="6">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-text-field
                  v-model="field.value"
                  outlined
                  :placeholder="field.placeholder"
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" no-gutter>
            <v-col v-if="field.name == 'id_type_client'" cols="6">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-select
                  outlined
                  :items="options"
                  item-text="typologie"
                  item-value="id_type_client"
                  v-model="field.value"
                  :placeholder="field.placeholder"
                  dense
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" no-gutter>
            <v-col v-if="field.name == 'id_camion'" cols="6">
              <ValidationProvider :name="field.label" :rules="field.rules">
                <v-select
                  outlined
                  :items="camions"
                  item-text="titre"
                  item-value="id_camion"
                  v-model="field.value"
                  :placeholder="field.placeholder"
                  dense
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row> -->
          </ValidationObserver>

          <v-row
            class="py-5"
            v-for="button in btn2"
            :key="button.name"
            align="center"
            justify="center"
          >
            <v-col v-if="button.method == 'createCreneau'">
              <v-btn
                width="500"
                color="#af2c70"
                class="text-uppercase"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>

            <v-col v-if="button.method == 'addTruck'">
              <v-btn
                width="500"
                color="#af2c70"
                class="text-uppercase"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>

            <v-col v-if="button.method == 'addCat'">
              <v-btn
                width="500"
                color="#af2c70"
                class="text-uppercase"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>

            <v-col v-if="button.method == 'createSecteur'">
              <v-btn
                width="300"
                color="#af2c70"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>

            <v-col v-if="button.method == 'addTarif'">
              <v-btn
                width="300"
                color="#af2c70"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>

            <v-col
              v-if="
                button.method == 'createCommune' ||
                  button.method == 'create' ||
                  button.method == 'update'
              "
            >
              <v-btn
                width="300"
                color="#af2c70"
                dark
                x-large
                @click="submit(button.method)"
              >
                {{ button.name }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-row
        class="py-5"
        v-for="button in btn2"
        :key="button.name"
        align="center"
        justify="center"
      >
        <v-col
          v-if="
            button.method == 'validate' && titleSecteur == 'AJOUTER UN PRODUIT'
          "
        >
          <v-btn
            color="#af2c70"
            dark
            width="100%"
            height="80"
            class="mt-5 py-5 font-weight-bold rounded-lg text-h4"
            @click="submit(button.method)"
          >
            {{ button.name }}
          </v-btn>
        </v-col>

        <v-col
          v-if="
            button.method == 'modify' && titleSecteur == 'MODIFIER UN PRODUIT'
          "
        >
          <v-btn
            color="#af2c70"
            dark
            width="100%"
            height="80"
            class="
              mt-5
              py-5
              font-weight-bold
              rounded-lg
              text-h4 text-capitalize
            "
            @click="submit(button.method)"
          >
            {{ button.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      foos: [
        { id: 1, date: new Date() },
        { id: 2, date: new Date() },
      ],
    };
  },

  props: {
    categorie: {
      type: Object,
      default: () => {},
    },
    tarifs: {
      type: Array,
      default: () => [],
    },
    showSecteurPop: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    camions: {
      type: Array,
      default: () => [],
    },
    btn2: {
      type: Array,
      default: () => [],
    },
    titleSecteur: {
      type: String,
      default: () => "",
    },
    roles: {
      type: Array,
      default: () => [],
    },
    days: {
      type: Array,
      default: () => [],
    },
    frequence: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    submit(method) {
      let errors = [];
      this.$refs.form.validate().then((valid) => {
        //display error
        console.log(this.$refs.form, 'composant');
        let e = this.$refs.form.errors;
        for (const field in e) {
          e[field].forEach((msg) => {
             console.log("🚀 ~ file: secteurPop.vue:622 ~ e[field].forEach ~ msg:", msg)
             msg = msg.includes("invalide")
                  ? msg.split("est")[0] + "doit être un entier ou un décimal"
                  : msg;
             msg = msg.replace("volume", "cubage" )
            errors.push({ msg: msg, id: Math.random() * 100 });
          });
        }

        this.$emit(method, { valid: valid, errors: errors, e: e });
      });
    },
  },

  computed: {
    dateRangeText() {
      let dates = [...this.fields[0].value];
      for (var i = 0; i < dates.length; i++) {
        dates[i] = this.$moment(dates[i]).format("DD/MM/YYYY");
      }
      return dates.join(" - ");
    },
    dateComputed () {
      if ( this.fields[0].value != "" ) {
        return this.$moment(this.fields[0].value).format("DD/MM/YYYY")
      } else {
        return ""
      }
    },
  },
};
</script>

<style scoped>

::v-deep .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
    height: auto;
    min-height: 56px;
}

.v-card {
  width: 58.71vw;
  max-width: 1088px;
  max-height: 700px;
  position: relative;
}

::v-deep .v-picker.v-card.v-picker--date.theme--light {
	width: 100%;
}

.heightCreateCreneau {
  height: 850px !important;
  max-height: 850px;
  max-width: 2000px;
}

::v-deep .v-text-field input::placeholder {
      font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

#close {
  cursor: pointer;
}
.rounded-lg.v-card.v-sheet.theme--light {
  border-radius: 17px !important;
}

::v-deep .v-input__slot {
  height: 56px;
}

#form .v-input {
  border: none !important;
}

#form ::v-deep .v-text-field input::placeholder {
  font-style: italic;
}
</style>
