<template>
  <v-main full-width class="clients">
    <v-row class="mr-4">
      <v-col cols="12">
        <p class="title ml-6 pt-4">CLIENTS</p>
        <div class="line ml-6"></div>
      </v-col>

      <v-col cols="12">
        <v-text-field
          solo
          dense
          flat
          placeholder="Rechercher un client par nom, marché... etc"
          class="autocomplete ml-6 mt-4"
          v-model="query"
        >
          <template class="searchField" slot="append">
            <img class="search-logo" src="@/assets/search.png" />
          </template>
        </v-text-field>

        <!-- {{clients}} <p>{{nbPage}} (nbPage) </p> <p>{{totalItems}} (totalItems)</p> -->
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="clients"
          :items-per-page="parseInt(displayItem)"
          :options.sync="options"
          :server-items-length="totalItems"
          :page="page"
          hide-default-header
          hide-default-footer
          no-data-text="Aucune donnée disponible"
          class="ml-6"
        >
          <template v-slot:[`item.id_client`]="{ item }">
            <router-link
              :to="{
                name: 'client',
                params: {
                  id_client: item.id_client,
                },
              }"
            >
              <span style="text-decoration: underline;">voir les détails</span>
            </router-link>
          </template>

          <template v-slot:[`item.logo`]="{ item }">
            <v-img
              max-height="60"
              max-width="25"
              :src="getImgUrl(item.logo)"
            ></v-img>
          </template>

          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.value"
                  class="column minWidth"
                  v-bind:class="[item.sortable ? 'sortable' : '']"
                  @click="item.sortable ? handler(item.value, options) : ''"
                >
                  {{ item ? item.text : "" }}

                  <i
                    v-if="
                      item == undefined ||
                        (options.sortBy == item.value &&
                          options.sortDesc == 'ASC')
                    "
                    class="fas fa-caret-up"
                  ></i>
                  <i v-else-if="item.text != ''" class="fas fa-caret-down"></i>
                </th>
              </tr>
            </thead>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="nbPage"
          circle
          class="mt-6"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import apiClients from "@/api/clients";

export default {
  name: "listClients",

  data() {
    return {
      clients: [],
      headers: [
        { text: "", align: "start", value: "logo", sortable: false },
        { text: "NOM PRENOM", value: "nom_prenom", sortable: true },
        { text: "CP VILLE", value: "cp_ville", sortable: true },
        { text: "TYPE", value: "type", sortable: true },
        { text: "BAILLEUR", value: "bailleur", sortable: true },
        { text: "MARCHE", value: "marche", sortable: true },
        { text: "NUMERO ADHERENT", value: "numero_adherent", sortable: true },
        { text: "", align: "start", value: "id_client", sortable: false },
      ],
      displayItem: process.env.VUE_APP_NB_ITEMS,
      page: 1,
      column: "nom",
      order: "DESC",
      query: "",
      nbPage: 0,
      totalItems: 0,
      options: {},
    };
  },
  watch: {
    async query(newValue, oldValue) {
      if (newValue != oldValue) {
        // console.log("newValue", newValue, "oldValue", oldValue, "watch parent");
        if (newValue.length >= 3) {
          this.getListClients(
            this.query,
            this.displayItem,
            0,
            this.column,
            this.order
          );
        } else {
          this.getListClients("", this.displayItem, 0, this.column, this.order);
        }
      }
    },
    async page(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getListClients(
          this.query,
          this.displayItem,
          this.page - 1,
          this.column,
          this.order
        );
      }
    },
  },
  components: {},

  props: {
    title: {
      type: String,
      default: () => "",
    },
  },

  methods: {
    handler(column, sort) {
      console.log("custom sort", sort.sortDesc);
      sort.sortBy = column;
      this.column = column;
      this.order = sort.sortDesc == "ASC" ? "DESC" : "ASC";
      this.page = sort.page;

      if (this.column != undefined) {
        this.column = column;
      }
      if (this.order != undefined) {
        this.order = sort.sortDesc == "ASC" ? "DESC" : "ASC";
      }

      if (this.column == "nom_prenom") {
        this.column = "nom";
      }
      if (this.column == "cp_ville") {
        this.column = "ville";
      }
      if (this.column == "type") {
        this.column = "typologie";
      }
      this.options.sortDesc = this.order;
      console.log(sort.sortDesc, sort.sortBy);
      console.log(this.column, this.order, this.page, "truccc");

      this.getListClients(
        this.query,
        this.displayItem,
        this.page - 1,
        this.column,
        this.order
      );
    },
    getListClients(query, displayItem, page, column, order) {
      apiClients
        .getClients(query, displayItem, page, column, order)
        .then((res) => {
          this.clients = res.data.client.rows;
          this.clients.forEach((client) => {
            client["logo"] = "images/clients/bonhommeVert.png";
          });
          this.nbPage = res.data.client.totalPages;
          this.totalItems = res.data.client.totalItems;
          console.log(res.data);
        });
    },
    getImgUrl(pic) {
      return require("../../../assets/" + pic);
    },
  },
  created() {
    this.getListClients(
      this.query,
      this.displayItem,
      this.page - 1,
      this.column,
      this.order
    );
  },
};
</script>

<style scoped>
::before {
  color: #93b121;
  margin-left: 5px;
}
::v-deep th.text-start.sortable span {
  font-size: 11px;
  color: #333333;
}
::v-deep td.text-start {
  font-weight: bold;
  font-size: 14px !important;
  color: #333333;
}
::v-deep td.text-start a {
  font-weight: 100;
  font-size: 11px !important;
  color: #af2c70;
}
.clients {
  background-color: #fff;
  height: calc(100vh - 100px);
  border-radius: 20px;
}

.title {
  text-align: left;
  color: #93b121;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal bold 1.14285714rem Roboto;
  margin-bottom: 0px;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #93b121;
  align-self: center;
  margin-top: 0.9vh;
  margin-bottom: -2vh;
}

.autocomplete {
  font: italic normal 100 24px/30px Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
  margin-top: 25px;
  margin-left: 1vw;
}

.v-input {
  border: 1px solid #e3e3e3 !important;
  border-radius: 15px !important;
  height: 65px;
}

::v-deep .autocomplete input::placeholder {
  font: italic normal bold 1.14285714rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

::v-deep .v-input__slot {
  margin-top: 10px !important;
}

::v-deep .v-text-field__slot {
  margin-left: 20px;
}

.search-logo {
  /* width: auto;
    max-width: 36px;
  height: auto; */
  cursor: pointer;
}
::v-deep tbody tr:nth-child(2n) {
  background-color: #f8f8f8;
}

.minWidth {
  min-width: 100px;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td,
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}
</style>
