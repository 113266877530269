<template>
  <v-overlay :value="show">
    <v-card light>
      <v-col justify="center" align="center" cols="12">
        <img id="close" src="@/assets/close.png" @click="$emit('close')" />
        <h3 class="mt-10">{{ title }}</h3>
        <v-row class="mt-16" justify="center" align="center">
          <div v-for="button in btn" :key="button.label">
            <v-btn
              v-if="button.label == 'Oui'"
              x-large
              elevation="2"
              color="#93b121"
              dark
              class="mx-2 mb-4"
              @click="$emit(button.action)"
            >
              {{ button.label }}
            </v-btn>
            <v-btn
              v-if="button.label == 'Non'"
              x-large
              elevation="2"
              color="#D61111"
              dark
              class="mx-2 mb-4"
              @click="$emit(button.action)"
            >
              {{ button.label }}
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    btn: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="css" scoped>
.v-card {
  width: 40vw;
  max-width: 1088px;
  height: 30vh;
  max-height: 500px;
  position: relative;
}

#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}
h4 {
  font-size: 2.1rem;
  font-weight: bold;
}
.v-btn.round {
  border-radius: 12px;
  font-weight: bold;
  font-size: 1em;
}
.theme--light.v-text-field--outlined >>> fieldset {
  border-color: #e8e8e8;
}
.v-textarea >>> textarea::placeholder {
  font-size: 24px;
  font-style: italic;
  font-weight: thin;
  color: #333;
  opacity: 1;
}
.uppercasePopup {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

#route
  > div
  > div:nth-child(2)
  > div
  > div
  > div.col-lg-4.col-4
  > div
  > div.v-overlay.v-overlay--active.theme--dark
  > div.v-overlay__content
  > div
  > div
  > div
  > button:nth-child(2)::v-deep {
  background-color: #af2c70;
  color: #fff;
  margin: 0 40px;
}

#route
  > div
  > div:nth-child(2)
  > div
  > div
  > div.col-lg-4.col-4
  > div
  > div.v-overlay.v-overlay--active.theme--dark
  > div.v-overlay__content
  > div
  > div
  > div
  > button:nth-child(1)::v-deep {
  background-color: #93b121;
  color: #fff;
  margin: 0 40px;
}
</style>