<template>
  <v-container fluid >
    <v-row no-gutters >
      <v-col cols="12" class="background">
        <OngletsAdministration/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OngletsAdministration from "@/components/admin/administration/ongletsAdministration";

export default {
  name: "Clients",

  data() {
    return {
      title: "Planning du jour",
      date: "",
    };
  },

  components: {
      OngletsAdministration
    
  },

  created() {
    // console.log("Role ", this.$store.state.user.Role.role);
  },
};
</script>

<style scoped>
@import "administration.css";
</style>
