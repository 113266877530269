<template>
  <v-overlay class="planning_detail" :value="show">
    <v-card light >
      <img id="close" src="@/assets/close.png" @click="$emit('close'), cubageTotal = 0, poidsTotal = 0" />
      <h3 class="mt-5 uppercasePopup title_back">{{ title }}</h3>
      <h4 class="mt-5"></h4>
      <!-- <v-col cols="10">
        <v-text-field
          solo
          dense
          flat
          placeholder="Rechercher une commune par nom ou code postal"
          class="autocomplete ml-6 mt-4"
          v-model="querySearch"
        >
          <template class="searchField" slot="append">
            <img class="search-logo" src="@/assets/search.png" />
          </template>
        </v-text-field>
      </v-col> -->
      <v-row>
        <v-col cols="12" offset="">
          <v-data-table
            :headers="headers"
            :items="planningDetails"
            hide-default-header
            hide-default-footer
            no-data-text="Aucune donnée disponible"
            class="ml-6 mt-3 mr-6 tablee"
          >
          <template v-slot:header="{ props: { headers } }">
              <th v-for="item in headers" :key="item.value" class="column" >
            {{ item.text.toUpperCase() }}</th>
          </template>

          <!-- <template v-slot:[`item.headers`]="{ item }" >
            <td class="text-center">{{ item.client }}</td>
          </template> -->

          <template v-slot:[`item.client`]="{ item }" >
            <td class="text-left" style="font-size: 1.20rem">{{ item.client }}</td>
          </template>
          <template v-slot:[`item.nbitem`]="{ item }" >
            <td class="text-left pl-6 data">{{ item.nbitem }}</td>
          </template>
          <template v-slot:[`item.cubage`]="{ item }" >
            <td class="text-left data">{{ item.cubage.toFixed(2) }}m³</td>
          </template>
          <template v-slot:[`item.poids`]="{ item }" >
            <td class="text-left  data">{{ item.poids }}Kg</td>
          </template>

          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <div class="line_planning ml-10 mr-10"></div>
      </v-row>

      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4" class="total">CUBAGE TOTAL: {{ cubageTotall }}m³</v-col>
        <v-col cols="3" class="total text-left">POIDS TOTAL: {{ poidsTotall }}T</v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <v-row style="height: 40px"></v-row>
    </v-card>
  </v-overlay>
</template>

<script>
// import api from "@/api/secteurs";

export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    planningDetails: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        { text: "client", align:"left", value: "client", width: "20", sortable: false },
        { text: "NOMBRE D'ITEM", align:"left", value: "nbitem", width: "40%", sortable: false },
        { text: "CUBAGE", align: "left", value: "cubage", width: "20%", sortable: false },
        { text: "POIDS", align: "left", value: "poids", width: "20%", sortable: false },
        { text: "     ", align: "center", value: "", sortable: false },
      ],
      cubageTotal: 0,
      poidsTotal: 0,
    };
  },
  methods: {
    // querySearch(query) {
    //   this.$emit("querySearch", { query: query, page: 0 });
    // },

    // changePage() {
    //   this.$emit("changePage");
    //   console.log(this.page, "changement page popup");
    // },
  },

  computed: {
    cubageTotall: function () {
      this.planningDetails.forEach(collecte => {
      this.cubageTotal += collecte.cubage
      });

      return this.cubageTotal.toFixed(2);
    },

    poidsTotall: function () {
      // `this` points to the vm instance
      this.planningDetails.forEach(collecte => {
      this.poidsTotal += collecte.poids
      });

      return (this.poidsTotal / 1000).toFixed(2);
    }
  },

  mounted() {
    // this.planningDetails.forEach(collecte => {
    //   this.cubageTotal += collecte.cubage
    //   this.poidsTotal += collecte.poids
    // });
    // this.poidsTotal = (this.poidsTotal / 1000).toFixed(2);
  }
};
</script>

<style lang="css" scoped>
 .title_back {
  background-color: #002b47;
  color: white;
  border-radius: 17px 17px 0px 0px;
  padding: 10px;
  font-size: 17px;
}
.v-card.v-sheet.theme--light {
  border-radius: 17px;
}
.planning_detail .v-card {
  width: 58.71vw;
  max-width: 1088px;
  height: auto;
  max-height: 700px;
  position: relative;
  padding: 0vw;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}
h4 {
  font-size: 2.1rem;
  font-weight: bold;
}
.v-btn.round {
  border-radius: 12px;
  font-weight: bold;
  font-size: 1em;
}
.theme--light.v-text-field--outlined >>> fieldset {
  border-color: #e8e8e8;
}
.v-textarea >>> textarea::placeholder {
  font-size: 24px;
  font-style: italic;
  font-weight: thin;
  color: #333;
  opacity: 1;
}
.uppercasePopup {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

.column {
color: #002B47;
font-size: 1.35rem;
}

.column:first-of-type {
  padding-left: 15px
}

.planning_detail .line_planning {
  width: 100%;
  background-color: #333;
  padding-top: 1px;
  /* margin: 20px 10px; */
  /* margin-left: 20px; */

}

th {
    width: 25%;
    text-align: left;
}

::v-deep tr {
  background-color: #F8F8F8;
}

::v-deep table {
	border-spacing: 0 5px !important;
	border-collapse: separate;
}

.data {
  font-weight: bold;
  color: #002B47;
  font-size: 1.20rem;
}

.total {
  color: #333;
  font-weight: bold;
  font-size: 1.20rem;
}
</style>
