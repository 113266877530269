<template>
  <v-container fluid>
    <v-row :justify="justifyContent" class="rowWidth" v-if="!tablet && !mobile">
      <v-col cols="5 text-left">
        <h1 v-if="
          $store.state.user
            ? $store.state.user.Role.role == 'administrateur'
            : ''
        " @click="goTo('clients')">
          RETOUR A LA LISTE DES CLIENTS
        </h1>
      </v-col>
      <v-col xl="3" lg="4" v-if="$route.name != 'createClient'"></v-col>
      <v-col cols="3" class="fullheight"></v-col>
      <v-col cols="5" v-if="$route.name != 'createClient'">
        <contact v-if="$route.name == 'client'" :client="client" @blackList="blackList" @errorFields="errorFields">
        </contact>
        <client-create v-if="$route.name == 'updateClient'" :client="client" :allMarches="allMarches"
          :allTypes="allTypes" @errorFields="errorFields"></client-create>
      </v-col>
      <v-col cols="6" offset="3" v-if="$route.name == 'createClient'">
        <client-create :client="client" :allMarches="allMarches" :allTypes="allTypes"
          @errorFields="errorFields"></client-create>
      </v-col>
      <v-col xl="3" lg="4" v-if="$route.name != 'createClient'">
        <historique :historique="client.historique_collectes" :vTotal="vTotal" :pTotal="pTotal" :tablet="tablet">
        </historique>
      </v-col>
      <v-col cols="3" class="fullheight" v-if="$route.name != 'createClient'">
        <nextCollect :client="client" :collect="nc" :infoCollecte="infoCollecte" @deleteColl="deleteColl"
          :hasMultipleCollecte="hasMultipleCollecte"></nextCollect>
        <commentaire class="mt-4" :commentaires="client.Commentaires" :commentairesPagination="commentaires"
          :client="client" @showCommentaire="showCommentaire" @addCommentaire="addCommentaire"
          @errorFields="errorFields" @page="page"></commentaire>
      </v-col>
    </v-row>

    <v-row :justify="justifyContent" v-if="tablet">
      <v-col cols="8" v-if="$route.name != 'createClient'">
        <client-create v-if="$route.name == 'updateClient'" :client="client" :allMarches="allMarches"
          :allTypes="allTypes" @errorFields="errorFields"></client-create>
        <contact v-if="$route.name == 'client'" :client="client" @blackList="blackList" @errorFields="errorFields">
        </contact>
        <commentaire v-if="$route.name != 'createClient'" :commentaires="client.Commentaires"
          :commentairesPagination="commentaires" :client="client" @showCommentaire="showCommentaire"
          @addCommentaire="addCommentaire" @errorFields="errorFields" @page="page" class="mt-4"></commentaire>
      </v-col>
      <v-col cols="8" offset="2" v-if="$route.name == 'createClient'">
        <client-create :client="client" :allMarches="allMarches" :allTypes="allTypes"
          @errorFields="errorFields"></client-create>
      </v-col>
      <v-col cols="4" id="sticky" v-if="$route.name != 'createClient'">
        <nextCollect :collect="nc" :client="client"></nextCollect>
        <historique v-if="$route.name != 'createClient'" :historique="client.historique_collectes" :vTotal="vTotal"
          :pTotal="pTotal" class="mt-4" :tablet="tablet" :hasMultipleCollecte="hasMultipleCollecte"></historique>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="mobile">
      <v-col cols="12">
        <client-create v-if="$route.name == 'createClient' || $route.name == 'updateClient'" :client="client"
          :allMarches="allMarches" :allTypes="allTypes" @errorFields="errorFields"></client-create>
        <contact v-if="$route.name == 'client'" :client="client" @blackList="blackList" @errorFields="errorFields">
        </contact>

        <nextCollect v-if="$route.name != 'createClient'" class="mt-4" :collect="nc" :client="client"
          @deleteColl="deleteColl($event)" :hasMultipleCollecte="hasMultipleCollecte" @reload="reload"></nextCollect>
        <historique v-if="$route.name != 'createClient'" :historique="client.historique_collectes" :vTotal="vTotal"
          :pTotal="pTotal" class="mt-4" :tablet="tablet"></historique>
        <commentaire v-if="$route.name != 'createClient'" :commentairesPagination="commentaires"
          :commentaires="client.Commentaires" :client="client" @showCommentaire="showCommentaire"
          @addCommentaire="addCommentaire" @errorFields="errorFields" @page="page" class="mt-4"></commentaire>
      </v-col>
      <v-col cols="4" id="sticky"> </v-col>
    </v-row>
    <div id="snack">
      <v-snackbar v-for="error in errors" :key="error" v-model="snackbar" :timeout="timeout" right rounded="pill"
        :color="color" elevation="24">
        {{ error }}
      </v-snackbar>
    </div>

    <v-overlay :value="showPopupDebutScript" id="script_debut">
      <v-card id="cardScript" class="debut" light>
        <img id="close" src="@/assets/close.png" @click="showPopupDebutScript = false" />
        <h1 v-if="client.id_marche == 1">Service des encombrants de la Communauté de Communes du Val d’Essonne, {{
          $store.state.user.prenom }} bonjour, comment puis-je vous aider ?</h1>
        <h1 v-if="client.id_marche == 2">Service Eco-Tri du LOGIS du SIREDOM, {{ $store.state.user.prenom }} bonjour,
          comment puis-je vous aider ? </h1>
        <h1 v-if="client.id_marche == 3">La Recyclerie du Gâtinais {{ $store.state.user.prenom }} bonjour !</h1>
        <h1 v-if="client.id_marche == 6">Service Allô encombrants {{ $store.state.user.prenom }} bonjour !</h1>
      </v-card>
    </v-overlay>

    <v-overlay :value="showPopup">
      <v-card id="cardEnd" light>
        <img id="close" src="@/assets/close.png" @click="showPopup = false" />
        <!-- CCVE -->
        <div v-if="client.id_marche == 1">
          <p>
            Je vous confirme que notre équipe passera le
            {{ $moment($store.state.selectedCreneau.date).format("LL") }} entre
            {{
              $moment($store.state.selectedCreneau.heure_debut, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            et
            {{
              $moment($store.state.selectedCreneau.heure_fin, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            pour collecter votre dépôt au {{ client.adresse }},
            {{ client.code_postal }} {{ client.ville }}.
          </p>

          <p>
            Pour cette collecte, nous vous rappelons qu’une participation
            financière de <span v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'">{{
              $store.state.Tarifs.tarif_administre }} €</span>
            <span v-else>{{ $store.state.Tarifs.tarif_administre }} €</span> sera facturé sur votre redevance d’ordures
            ménagères.
          </p>

          <p>
            Si vous souhaitez annuler ou reprogrammer ce RDV, vous pouvez nous
            rappeler à ce même numéro dans les 48h ouvrés avant la date prévue.
            Passé ce délai, sachez qu’il vous sera facturé <span
              v-if="client.TypeClient && client.TypeClient.typologie == 'particulier'">{{
                $store.state.Tarifs.tarif_administre }} €</span>
            <span v-else>{{ $store.state.Tarifs.tarif_administre }} €</span> .
          </p>

          <p>Avez-vous des questions ?</p>

          <p>Vous recevrez un rappel de ce RDV par sms et/ou email.</p>

          <p>Au revoir</p>
        </div>
        <div v-if="client.id_marche == 2">
          <p>
            Je vous confirme que notre équipe passera le
            {{ $moment($store.state.selectedCreneau.date).format("LL") }} entre
            {{
              $moment($store.state.selectedCreneau.heure_debut, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            et
            {{
              $moment($store.state.selectedCreneau.heure_fin, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            pour collecter votre dépôt au {{ client.adresse }},
            {{ client.code_postal }} {{ client.ville }}.
          </p>

          <p v-if="
            $store.state.gratuit
          ">
            Vous faites partie des personnes pouvant bénéficier d’une collecte
            annuelle gratuite. Vous devrez pour cela présenter une pièce
            d’identité / votre carte d’invalidité.
          </p>
          <p v-else>
            Pour cette collecte, vous recevrez une facture du Siredom d’un
            montant de {{ $store.state.Tarifs.tarif_administre }} €.
          </p>

          <p>
            Si vous souhaitez annuler ou reprogrammer ce RDV, vous pouvez nous
            rappeler à ce même numéro dans les 48h ouvrés avant la date prévue.
            Passé ce délai, sachez qu’il vous sera facturé {{ $store.state.Tarifs.tarif_cancel }}€. Sachez également que
            si vous étiez absent le jour de
            l’enlèvement, nous ne pourrions pas réaliser le retrait et le
            déplacement vous serait facturé {{ $store.state.Tarifs.tarif_cancel }} €.
          </p>

          <p>Avez-vous des questions ?</p>

          <p>Vous recevrez un rappel de ce RDV par sms et/ou email.</p>

          <p>Au revoir</p>
        </div>
        <div v-if="client.id_marche == 6">
          <p>
            Je vous confirme que l'équipe de collecte passera le
            {{ $moment($store.state.selectedCreneau.date).format("LL") }} entre
            {{
              $moment($store.state.selectedCreneau.heure_debut, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            et
            {{
              $moment($store.state.selectedCreneau.heure_fin, "hh:mm").format(
                "HH[h]mm"
              )
            }}
            pour enlever votre dépôt au {{ client.adresse }},
            {{ client.code_postal }} {{ client.ville }}.
            N'oubliez pas de sortir vos encombrants la veille au soir.
            Un rappel de ce RDV vous parviendra par sms et/ou courriel. Avez-vous des questions ?
            Merci pour votre confiance. Au revoir.
          </p>
          <p v-if="
            $store.state.gratuit
          ">
            Vous faites partie des personnes pouvant bénéficier d’une collecte
            annuelle gratuite. Vous devrez pour cela présenter une pièce
            d’identité / votre carte d’invalidité.
          </p>
          <p v-else>
            Pour cette collecte, vous recevrez une facture du Siredom d’un
            montant de {{ $store.state.Tarifs.tarif_administre }} €.
          </p>
          <p>
            Si vous souhaitez annuler ou reprogrammer ce RDV, vous pouvez nous
            rappeler à ce même numéro dans les 48h ouvrés avant la date prévue.
            Passé ce délai, sachez qu’il vous sera facturé {{ $store.state.Tarifs.tarif_cancel }}€. Sachez également que
            si vous étiez absent le jour de
            l’enlèvement, nous ne pourrions pas réaliser le retrait et le
            déplacement vous serait facturé {{ $store.state.Tarifs.tarif_cancel }} €.
          </p>
          <p>Avez-vous des questions ?</p>
          <p>Vous recevrez un rappel de ce RDV par sms et/ou email.</p>
          <p>Au revoir</p>
        </div>
      </v-card>
    </v-overlay>


  </v-container>
</template>

<script>
// Composant
import commentaire from "@/components/clients/commentaires/commentaires";
import contact from "@/components/clients/contact/contact";
import historique from "@/components/clients/historique/historique";
import nextCollect from "@/components/clients/nextCollect/nextCollect";

import ClientCreate from "../../components/clients/create/create.vue";
// import UpdateClient from "../../components/clients/update/updateClient.vue";

// API

import api from "@/api/clients";
import apiMarches from "@/api/marches";
import apiTypes from "@/api/types";
import apiCollecte from "@/api/collectes";

export default {
  components: {
    commentaire,
    contact,
    historique,
    nextCollect,
    ClientCreate,
  },
  data() {
    return {
      client: {},
      vTotal: 0,
      pTotal: 0,
      snackbar: false,
      timeout: 3000,
      errors: [],
      windowWidth: window.innerWidth,
      tablet: false,
      mobile: true,
      allMarches: [],
      allTypes: [],
      justifyContent: this.$route.name != "createClient" ? "center" : "initial",
      color: "red darken-2",
      infoCollecte: {},
      commentaires: {},
      pagination: 0,
      nc: {},
      hasMultipleCollecte: false,
      fromName: "",
      showPopup: false,
      showPopupDebutScript: false
    };
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth <= 1200 && newWidth > 900) {
        this.tablet = true;
        this.mobile = false;
      } else if (newWidth <= 900) {
        this.tablet = false;
        this.mobile = true;
      } else {
        this.tablet = false;
        this.mobile = false;
      }
    },
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    async reload() {
      await api
        .commentaires(this.$route.params.id_client, this.pagination)
        .then((res) => {
          console.log("alex", this.page);
          this.commentaires = res.data.client;
        }),
        api.getClientInfos(this.$route.params.id_client).then((res) => {
          this.client = res.data.client;
          console.log(this.client, "clii");
          //Store les infos clients dans vuex
          this.$store.commit("infosClient", this.client);
          this.vTotal = 0;
          this.pTotal = 0;
          this.client.historique_collectes.forEach((collect) => {
            this.vTotal += collect.volume;
            this.pTotal += collect.poids;
          });

          this.commentaires.rows.forEach((commentaire, i) => {
            if (i == 0) {
              this.$set(this.commentaires.rows[i], "show", true);
            } else {
              this.$set(this.commentaires.rows[i], "show", false);
            }
          });

          if (this.client.nextCollect.length > 0) {
            this.nc = this.client.nextCollect[0];
            this.hasMultipleCollecte = true;

            console.log(
              this.client.nextCollect,
              this.client,
              "pppppp",
              this.hasMultipleCollecte
            );
            apiCollecte
              .getCollecte(this.client.nextCollect[0].id_collecte)
              .then((res) => {
                this.infoCollecte = res.data.collecte[0];
                console.log("Bruhhh ", this.infoCollecte);
                this.fillCart(this.infoCollecte.Collectibles_a_Recuperers);
              });
            // if(this.$route.name == "modifierCollecte"){
            //   //this.fillCart()
            // }
          }
        });
    },

    fillCart(cart) {
      console.log(cart, "cart");

      let products = cart.map((product) => {
        let p = product.Collectible;
        p.quantity = product.quantite;
        return p;
      });
      this.$store.commit("fillCart", products);
      console.log(products, "products");
    },

    deleteColl(data) {
      console.log("delete collecte in clientvue yyyyy", data);
      apiCollecte
        .deleteCollecte(data.id_collecte, data.commentaire)
        .then((res) => {
          console.log(res.data.message);
          this.reload();
        }, err => {
          console.log('je tombe dans une errerur', err);
        });

      this.$store.state.collecteCollectibles = [];
    },

    blackList(value) {
      api.blacklist(this.client.id_client, value).then(() => {
        this.reload();
      });
    },
    addCommentaire(value) {
      api.nouveauCommentaire(this.$route.params.id_client, value).then(() => {
        this.reload();
      });
    },
    showCommentaire(index) {
      console.log(this.commentaires.rows[index].show);
      this.commentaires.rows.forEach((commentaire) => {
        commentaire.show = false;
      });
      this.commentaires.rows[index].show = !this.commentaires.rows[index].show;
      console.log(this.commentaires.rows[index].show);
    },
    errorFields(errorObj) {
      this.errors = [];
      this.snackbar = false;
      console.log("emit dans clients", errorObj);
      errorObj.errors.forEach((element) => {
        this.errors.push(element);
      });
      this.snackbar = true;
      this.color = errorObj.color;
      console.log(this.errors, this.snackbar);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    page(e) {
      this.pagination = e;
      this.reload();
    },
    closePopup() {
      console.log("close popup");
      this.showPopup = false;
      console.log(this.showPopup, "ccccccccccccccccccccc");
    },
  },
  mounted() {
    console.log(this.fromName, "route from mounted");
    if (this.fromName == "planning" || this.fromName == "update_planning" || (this.fromName == "updateCollecte" && this.$store.state.fromUpdatePanier == true)) {
      console.log("from planning mouted");
      this.showPopup = true;
      this.$store.commit('isFromUpdatePanier', false);

    }
    if (this.fromName == 'call') {
      this.showPopupDebutScript = true;
      console.log('vient de call', this.showPopupDebutScript)
    }
    this.$nextTick(() => {
      if (
        this.$route.from == "planning" ||
        this.$route.from == "update_planning"
      ) {
        console.log("from planning created");
      }

      window.addEventListener("resize", this.onResize);

      if (this.windowWidth <= 1200 && this.windowWidth > 900) {
        this.tablet = true;
        this.mobile = false;
      } else if (this.windowWidth <= 900) {
        this.tablet = false;
        this.mobile = true;
      } else {
        this.tablet = false;
        this.mobile = false;
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromName = from.name;
      console.log(from.name, "beforerouteenter");
    });
  },

  created() {
    //Appel pour un client
    if (this.$route.name == "client" || this.$route.name == "updateClient") {
      this.reload();
    }

    if (
      this.$route.from == "planning" ||
      this.$route.from == "update_planning"
    ) {
      console.log("from planning created");
    }

    if (
      this.$route.name == "createClient" ||
      this.$route.name == "updateClient"
    ) {
      apiMarches.getAllMarches().then((res) => {
        this.allMarches = res.data.marches;
      });
      apiTypes.getAllTypes().then((res) => {
        this.allTypes = res.data.types;
      });
    }
    if (this.$route.name == "createClient") {
      this.client = {
        civilite: "",
        nom: "",
        prenom: "",
        date_naissance: "",
        id_marche: "",
        numero_adherent: "",
        id_type_client: 1,
        adresse: "",
        complement_adresse: "",
        informations: "",
        telephone_fixe: "",
        telephone_mobile: "",
        email: "",
        contact_privilegie: "",
        id_commune: 0,
      };
    }
  },
};
</script>

<style scoped>
.fullheight {
  display: flex;
  flex-direction: column;
}

.container {
  align-items: center;
  display: flex;

  height: inherit;
}

h1 {
  color: #ea7e2a;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.rowWidth {
  width: 100%;
}

#close {
  position: absolute;
  right: -15px;
  top: -35px;
}

#cardEnd p {
  font-size: 1.2rem;
}

#cardEnd {
  width: 80%;
  margin: auto;
}

#cardEnd p:nth-child(1) {
  padding-top: 40px;
}

#cardScript {
  width: 80vw;
  height: 20vh;
}

#cardScript h1 {
  font-size: 2rem;
}

#cardScript.debut {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366px) {
  .container {
    height: initial;
  }
}

@media screen and (max-width: 1200px) {
  #sticky {
    position: sticky;
    top: 0;
    height: 100%;
  }
}
</style>
